import {Component, OnInit} from '@angular/core';
import {ToastService} from '@nightsbridge/common-ui/toasts';
import {AccountItemActionsComponent} from 'src/app/components/account-info/widgets/account-summary/account-item-actions/account-item-actions.component';
import {AccountService} from 'src/app/service/account.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {CredentialService} from 'src/app/service/credential.service';
import {LoadingService} from 'src/app/service/loading.service';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {PaymentService} from 'src/app/service/payment.service';
import {TranslateService} from 'src/app/service/translate.service';

@Component({
  selector: 'app-payment-actions',
  templateUrl: './payment-actions.component.html',
  styleUrls: ['./payment-actions.component.scss']
})
export class PaymentActionsComponent extends AccountItemActionsComponent implements OnInit {

  constructor(
    pageRouteService: PageRouteService,
    pageRouteDataService: PageRouteDataService,
    accountService: AccountService,
    paymentService: PaymentService,
    toastService: ToastService,
    loadingService: LoadingService,
    confirmationService: ConfirmationService,
    authService: CredentialService,
    translateService: TranslateService) {
    super(
      pageRouteService,
      pageRouteDataService,
      accountService,
      paymentService,
      toastService,
      loadingService,
      confirmationService,
      authService,
      translateService
    );
  }

  close(returnData?) {
    this.pageRouteService.back(returnData);
  }

}
