<app-tap-n-pay-modal-container>
        <div class="d-flex justify-content-center my-3">
            <img src="assets/images/tap_n_pay.svg"/>
        </div>
        <div class="d-flex justify-content-center flex-column mt-3 p-3 message-error-action" *ngIf="showErrorMessage">
            <svg class="ic align-self-center exclamation-icon me-2 fw-bold message-error-action-text mb-2">
                <use href="assets/images/sprite.svg#error-outlined"/>
            </svg>
            <div class="d-flex mb-2 message-error-action-text">
                <div class="fw-bold fs-16 text-center w-100">{{ errorMessage?.heading }}</div>
            </div>
            <div class="fs-14 mb-2 fw-semi-bold text-center">{{ errorMessage?.subtitle }}</div>
        </div>
        <hr>
        <div class="d-flex flex-column p-2">
            <div class="d-flex">
                <div class="fw-semi-bold flex-1 fs-14"> Booking Value:</div>
                <div class="flex-2 fs-14"> {{ amountDue | number : '1.2-2' }}</div>
            </div>
            <div class="d-flex">
                <div class="fw-semi-bold flex-1 fs-14"> Date:</div>
                <div class="flex-2 fs-14"> {{ currentDate | date : dateFormat }}</div>
            </div>
            <div class="d-flex" *ngIf="bookingID > 0">
                <div class="fw-semi-bold flex-1 fs-14"> Booking ID:</div>
                <div class="flex-2 fs-14"> {{bookingID}} </div>
            </div>
            <div class="d-flex" *ngIf="clientFullName">
                <div class="fw-semi-bold flex-1 fs-14"> Made By:</div>
                <div class="flex-2 fs-14"> {{ clientFullName }}</div>
            </div>

            <div class="d-flex" *ngIf="fromDate">
                <div class="fw-semi-bold flex-1 fs-14"> From:</div>
                <div class="flex-2 fs-14"> {{ fromDate | date : dateFormat }}</div>
            </div>
            <div class="d-flex" *ngIf="toDate">
                <div class="fw-semi-bold flex-1 fs-14"> To:</div>
                <div class="flex-2 fs-14"> {{ toDate | date : dateFormat }}</div>
            </div>
        </div>

        <div class="my-2">
            <form *ngIf="amount !== null && amountDue">
                <cui-input (onBlur)="changeRate()" [formControl]="amountControl" sizing="sm" label="Amount"
                           class="w-100" [classes]="amount > amountDue ? 'warning-input': ''">
                </cui-input>
                <div class="fs-12 p-1 fw-semi-bold"> Minimum Payment Allowed: {{ minAmount }}.00</div>
                <ng-container *ngIf="amountControl.touched">
                    <div *ngIf="amountControl.hasError('required')" class="invalid-feedback fs-12 p-1"> Required</div>
                </ng-container>
            </form>
        </div>
</app-tap-n-pay-modal-container>

<div class="d-flex modal-footer border-top justify-content-between">
    <cui-button class="d-flex flex-1" (onClick)="activeModal.close()" label="Cancel" name="cancel" classes='tap-n-pay-btn-cancel tap-n-pay-btn-action-override' mode="light"></cui-button>
    <cui-button class="d-flex flex-1" (onClick)="confirm()" classes="tap-n-pay-btn-success tap-n-pay-btn-action-override" label="Confirm" name="confirm" mode="light"
                [disabled]="!(this.convertCurrencyToNumber(amount) >= (this.minAmount))"></cui-button>
</div>
