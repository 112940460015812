<div class="px-3 pb-3">
  <div class="fw-semibold pb-3">Choose an account for {{client.fullname}}</div>

  <div *ngIf="!isMobile" class="table-scrollable-md overflow-y-auto">
    <table class="table table-striped table-hover table-responsive border-top max-h-560 w-600 border rounded">
      <thead class="border-bottom h-45">
        <tr>
          <th scope="col" class="fw-semibold">A/C IDs</th>
          <th scope="col" class="fw-semibold">Account Details:</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let account of clientAccounts; let i = index" >
          <tr *ngIf="account.details.isbooking" (click)="moveToAccount(account)" [ngClass]="{'d-none': sameAccount(account)}">
            <td class="typography__p text-start">{{account.bbaccountid}}</td>
            <td class="typography__p text-start">BookingID - {{account.details.bbbookingid}} /
              {{account.details.clientname}}
              arriving {{account.details.fromdate | date:'dd/MM/yyyy'}} - {{account.details.todate | date:'dd/MM/yyyy'}}
            </td>
          </tr>
          <tr *ngIf="!account.details.isbooking" (click)="moveToAccount(account)" [ngClass]="{'d-none': sameAccount(account)}">
            <td class="typography__p text-start">{{account.bbaccountid}}</td>
            <td class="typography__p text-start">Extra - {{account.details.extradate}} ({{account.details.qty}}x
              {{account.details.stockname}})</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div *ngIf="isMobile">
    <div class="d-flex flex-column p-2 mt-2 account border rounded-3" *ngFor="let account of clientAccounts; let i=index;"
      (click)="moveToAccount(account)" [ngClass]="{'d-none': sameAccount(account)}">
      <div *ngIf="account.details.isbooking">
        <div>
          <span class="fw-bold">A/C ID: </span><span>{{account.bbaccountid}}</span>
        </div>
        <div>
          <span class="fw-bold">Account Details: </span><span>BookingID - {{account.details.bbbookingid}}</span>
        </div>
      </div>
      <div *ngIf="!account.details.isbooking">
        <div>
          <span class="fw-bold">A/C ID: </span><span>{{account.bbaccountid}}</span>
        </div>
        <div>
          <span class="fw-bold">Account Details: </span><span>Extra - {{account.details.extradate}}
            ({{account.details.qty}}x {{account.details.stockname}})</span>
        </div>
      </div>
    </div>
  </div>
</div>