<div class="clientAddContainer">
  <div class="modal-header justify-content-between">
    <span></span>
    <div class="text-center">
      <span class="typography__h3" *ngIf="!hasClient()">
        {{'clientAdd' | translate: 'clientAdd'}}
      </span>
      <span class="typography__h3" *ngIf="hasClient()">
        {{'clientAdd' | translate: 'clientEdit'}}
      </span>
    </div>
    <div class="close-modal" (click)="back()">
      <span class="helper"></span>
      <img src="assets/images/close.svg" class="display__end"/>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="clientForm" class="d-flex flex-column h-100">
      <div class="row">
        <div class="col-6">
          <label class=" inputField__label">
            {{ 'clientAdd' | translate: 'type' }}
          </label>
          <ng-select formControlName="clienttype" [clearable]="false" [searchable]="false">
            <ng-option *ngFor="let option of clienttypes" [value]="option.value" class="typography__p">
              {{'clientType' | translate: option.name}}
            </ng-option>
          </ng-select>
        </div>
        <div class="col-sm-2">
          <label class=" inputField__label">
            {{ 'clientAdd' | translate: 'clientid' }}
          </label>
          <input type="text" formControlName="bbclientid" readonly/>
        </div>
        <div class="col-sm-4">
          <label class=" inputField__label">
            {{ 'clientAdd' | translate: 'created' }}
          </label>
          <input type="text" formControlName="datecreated" readonly/>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="d-flex">
            <label class=" inputField__label clientAddContainer__title-input">
              {{ 'clientAdd' | translate: 'title' }}
            </label>
            <label class=" inputField__label clientAddContainer__firstname-input">
              {{ 'clientAdd' | translate: 'firstname' }}
            </label>
            <label class=" inputField__label clientAddContainer__surname-input">
              {{ 'clientAdd' | translate: 'surname' }}
            </label>
          </div>
          <div class="d-flex">
            <input type="text" formControlName="title" class="clientAddContainer__title-input" maxlength="10"/>
            <input type="text" formControlName="firstname" class="clientAddContainer__firstname-input form-control" maxlength="20"/>
            <input type="text" formControlName="surname" class="clientAddContainer__surname-input form-control" maxlength="40"/>
          </div>
        </div>
        <div class="col-sm-6">
          <label class="inputField__label">
            {{ 'clientAdd' | translate: 'company' }}
          </label>
          <div class="d-flex align-items-center">
            <input type="text" formControlName="company" maxlength="80" class="form-control"/>
            <button class="btn__icon btn__secondary-icon m-left-12" ngbTooltip="{{ 'clientAdd' | translate: 'searchCompany' }}"
                    tooltipClass="tooltip__container">
              <img src="assets/images/icons/ic_person-ic_search.svg" (click)="selectCompany()"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <label class="inputField__label">
            {{ 'clientAdd' | translate: 'phoneno' }}
          </label>
          <div>
            <input type="text" formControlName="phoneno" maxlength="40" />
          </div>
        </div>
        <div class="col-sm-6">
          <label class="inputField__label">
            {{ 'clientAdd' | translate: 'email' }}
          </label>
          <div class="d-flex align-items-center">
            <input type="text" formControlName="email" maxlength="80"/>
            <button class="btn__icon btn__regular-icon--active-blue m-left-12" *ngIf="hasClient()">
              <a href="mailto:{{clientObj.client.email}}">
                <svg class="ic ic_mail">
                  <use href="assets/images/icons/sprite.svg#ic_mail" />
                </svg>
              </a>
            </button>
          </div>
        </div>
      </div>
      <div class="my-3 d-flex flex-column overflow-auto clientTabs__content">
        <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeID" >
          <!-- Vat -->
          <li [ngbNavItem]="1">
            <a ngbNavLink>{{'clientAdd' | translate: 'vatAddress'}}</a>
            <ng-template ngbNavContent>
              <div class="col-sm-6 offset-sm-1" formGroupName="clientaddr">
                <div class="d-flex justify-content-start align-items-center py-1">
                  <label class="col-sm-3  inputField__label">
                    {{ 'clientAdd' | translate: 'vatNo' }}
                  </label>
                  <div class="col-sm-5">
                    <input type="text" formControlName="vatno" maxLength="10"/>
                  </div>
                </div>
                <div class="d-flex justify-content-start align-items-center py-1">
                  <label class="col-sm-3  inputField__label">
                    {{ 'clientAdd' | translate: 'address' }}
                  </label>
                  <div class="col-sm-9 d-flex align-items-center">
                    <input type="text" formControlName="add1" maxLength="200"/>
                  </div>
                </div>
                <div class="d-flex justify-content-start align-items-center py-1">
                  <label class="col-sm-3  inputField__label">
                    {{ 'clientAdd' | translate: 'address2' }}
                  </label>
                  <div class="col-sm-9 d-flex align-items-center">
                    <input type="text" formControlName="add2" maxLength="200"/>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <!-- Extra 2 -->
          <li [ngbNavItem]="2">
            <a ngbNavLink>{{'clientAdd' | translate: 'extraInformation'}}</a>
            <ng-template ngbNavContent>
              <div class="d-flex align-items-center justify-content-center">
                <div class="col-sm-6">
                  <label class="inputField__label">
                    {{ 'clientAdd' | translate: 'attribute' }}
                  </label>
                </div>
                <div class="col-sm-6 px-2">
                  <label class="inputField__label">
                    {{ 'clientAdd' | translate: 'value' }}
                  </label>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center py-1 row" formArrayName="clientextras"
                   *ngFor="let extra of clientextras.controls; let i = index">
                <div class="row" [formGroupName]="i">
                  <div class="col-sm-6">
                   <ng-select class="select__client-extras" formControlName="fieldtype" dropdownPosition="bottom" [clearable]="false">
                      <ng-option *ngFor="let option of clientAttributes" [value]="option.attributetype" class="typography__p">
                        {{option.attributetext}}
                      </ng-option>
                    </ng-select>
                  </div>

                  <div class="col-sm-6 d-flex">
                    <input type="text" maxLength="30" formControlName="fieldvalue" />

                    <button  class="btn__icon btn__red-cancel-icon m-left-12" (click)="removeClientExtra(i)">
                      <svg class="ic ic_minus_circle">
                        <use href="assets/images/icons/sprite.svg#ic_minus_circle" />
                      </svg>
                    </button>
                    <button class="btn__icon btn__green-confirm-icon m-left-12" (click)="addClientExtra()" >
                      <svg class="ic ic_plus_circle">
                        <use href="assets/images/icons/sprite.svg#ic_plus_circle" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <!-- additional 3 -->
          <li [ngbNavItem]="3">
            <a ngbNavLink>{{'clientAdd'| translate: 'additional'}}</a>
            <ng-template ngbNavContent>
              <div>
                <label class="inputField__label">
                  {{ 'clientAdd' | translate: 'ratesheetInUse' }}
                </label>
                <div>
                  <ng-select formControlName="bbratesheetid" (change)="rateSheetChanged()" [clearable]="false"
                             [searchable]="false" appendTo=".modal-content">
                    <ng-option *ngFor="let option of clientObj.ratesheets" [value]="option.bbratesheetid"
                               class="typography__p">
                      {{option.ratesheetname}}
                    </ng-option>
                  </ng-select>
                </div>
              </div>

              <div>
                <label class="inputField__label">
                  {{ 'clientAdd' | translate: 'notes' }}
                </label>
                <div>
                  <textarea rows="4" formControlName="notes"></textarea>
                </div>
              </div>
            </ng-template>
          </li>
          <!-- account 4 -->
          <li [ngbNavItem]="4" *ngIf="showAccounts()">
            <a ngbNavLink>{{'clientAdd' | translate: 'accounts'}}</a>
            <ng-template ngbNavContent>
              <div class="table_container__scrollable table_container__scrollable__small">
                <table class="table__bordered_style">
                  <thead>
                  <tr>
                    <th class="col-sm-3"><span>{{ 'clientAdd' | translate: 'accountId' }}</span></th>
                    <th class="col-sm-6"><span>{{ 'clientAdd' | translate: 'reference' }}</span></th>
                    <th class="col-sm-3"><span>{{ 'clientAdd' | translate: 'status' }}</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let account of clientObj.accounts" (click)="viewAccount(account.accountid)">
                    <td class="col-sm-2">
                      <div><span>{{ account.bbaccountid }}</span></div>
                    </td>
                    <td class="col-sm-6">
                      <div><span>{{ account.reference }}</span></div>
                    </td>
                    <td class="col-sm-3">
                      <div><span>{{ 'accountStatus' | translate : account.status }}</span></div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </li>
          <!-- transaction 5 -->
          <li [ngbNavItem]="5" *ngIf="hasClient()">
            <a ngbNavLink>Transactions</a>
            <ng-template ngbNavContent>
              <div class="table_container__scrollable table_container__scrollable__small">
                <table class="table__bordered_style table__scrollbar table__wrapper_scroll_y">
                  <thead>
                  <tr>
                    <th class="col-sm-2"><span>{{ 'clientAdd' | translate: 'transactionDate' }}</span></th>
                    <th class="col-sm-2"><span>{{ 'clientAdd' | translate: 'transactionType' }}</span></th>
                    <th class="col-sm-2"><span>{{ 'clientAdd' | translate: 'transactionNo' }}</span></th>
                    <th class="col-sm-1"><span>{{ 'clientAdd' | translate: 'amount' }}</span></th>
                    <th class="col-sm-3"><span>{{ 'clientAdd' | translate: 'reference' }}</span></th>
                    <th class="col-sm-3"><span>{{ 'clientAdd' | translate: 'accountId' }}</span></th>
                    <th class="col-sm-1"><span>
                         <button
                           class="d-flex align-items-center justify-content-center btn btn__sm btn__primary display__end"
                           (click)="addTransaction()">
                            {{'clientAdd'|translate:'add'}}
                         </button>
                      </span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let transaction of clientObj.transactions" (click)="viewTransaction(transaction)" [ngStyle]="{'display': !pdfViewerActive || (pdfViewerActive && (invoiceTemplatePreference === constants.EXCEL || invoiceTemplatePreference === constants.NOSELECTION)) ? 'table-row' : 'none'}">
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ formatDate(transaction.trandate) }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ transaction.trantype }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ transaction.tranno }}</span></div>
                    </td>
                    <td class="col-sm-1 typography__p">
                      <div class="currencyTextAlign"><span>{{ transaction.amount | currency }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ transaction.reference }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ transaction.accountid == 0 ? 'Unallocated' : getAccountID(transaction.accountid)}}</span></div>
                    </td>
                    <td class="col-sm-1" (click)="viewTransaction(transaction)"></td>
                  </tr>
                  <tr *ngFor="let transaction of clientObj.transactions" [ngStyle]="{'display': pdfViewerActive && invoiceTemplatePreference === constants.PDF ? 'table-row' : 'none'}">
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ formatDate(transaction.trandate) }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ transaction.trantype }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ transaction.tranno }}</span></div>
                    </td>
                    <td class="col-sm-1 typography__p">
                      <div class="currencyTextAlign"><span>{{ transaction.amount | currency }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ transaction.reference }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ transaction.accountid == 0 ? 'Unallocated' : getAccountID(transaction.accountid)}}</span></div>
                    </td>
                    <td class="col-sm-1">
                      <span *ngIf="transaction.trantype == 'CRN' && transaction.tranno == latestCreditNote">
                         <button class="d-flex align-items-center justify-content-center btn btn__sm btn__primary display__end"
                                 (click)="openHTMLViewer(transaction)" *ngIf="transaction.trantype == 'CRN' && transaction.tranno == latestCreditNote">
                            {{'general'|translate:'view'}}
                         </button>
                      </span>
                      <span *ngIf="transaction.trantype == 'INV' && transaction.tranno == latestInvoice">
                         <button class="d-flex align-items-center justify-content-center btn btn__sm btn__primary display__end"
                                 (click)="openHTMLViewer(transaction)" *ngIf="transaction.trantype == 'INV' && transaction.tranno == latestInvoice">
                            {{'general'|translate:'view'}}
                         </button>
                      </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </li>
          <!-- history 6 -->
          <li [ngbNavItem]="6" *ngIf="hasClient()">
            <a ngbNavLink>
              {{'clientAdd' | translate: 'history'}}
            </a>
            <ng-template ngbNavContent>
              <div class="table_container__scrollable table_container__scrollable__small">
                <table class="table__bordered_style">
                  <thead>
                  <tr>
                    <th class="col-sm-2 typography__p"><span>{{ 'clientAdd' | translate: 'bookingid' }}</span></th>
                    <th class="col-sm-2 typography__p"><span>{{ 'clientAdd' | translate: 'arriving' }}</span></th>
                    <th class="col-sm-2 typography__p"><span>{{ 'clientAdd' | translate: 'leaving' }}</span></th>
                    <th class="col-sm-2 typography__p"><span>{{ 'clientAdd' | translate: 'nights' }}</span></th>
                    <th class="col-sm-2 typography__p"><span>{{ 'clientAdd' | translate: 'total' }}</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let booking of clientObj.bookings" (click)="navigateToBooking(booking)">
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ booking.bbbookingid }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ formatDate(booking.fromdate) }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ formatDate(booking.todate) }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ booking.nights }}</span></div>
                    </td>
                    <td class="col-sm-2 typography__p">
                      <div><span>{{ booking.bookingtotal | currency }}</span></div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="overflow-y-only"></div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex align-items-center justify-content-between">
    <div>
      <button *ngIf="hasClient()" class="btn__regular btn btn__primary"
              (click)="openStatementModal()">{{'clientAdd'|translate:'statement'}}
      </button>
    </div>
    <div class="d-flex justify-content-start align-items-center" *ngIf="hasClient()">
      <label class=" inputField__label col-sm-6">
        {{ 'clientAdd' | translate: 'accountBalance' }}
      </label>
      <div class="col-sm-7 d-flex align-items-center">
        <input type="text" [value]="clientObj?.balanceOnAcc |currency " readonly disabled class="currencyTextAlign"/>
      </div>
    </div>
    <button class="btn__regular btn btn__green" (click)="saveClient()" >
      {{'general'|translate:'save'}}
    </button>
  </div>
</div>
