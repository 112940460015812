import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {CredentialService} from './credential.service';
import {ToastService} from '@nightsbridge/common-ui/toasts';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private copy: boolean = false;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private cuiToastService: ToastService,
    private credentialService: CredentialService) { }

  sendEmail(emailAddresses: string, template: string, templateno: string, clientId: number, copy: boolean) {
    const data = {
      emailAddresses: emailAddresses.split(',').filter(str => !!str).map(str => str.trim()),
      template,
      templateno,
      clientid: clientId,
      messagename: 'DocumentTemplateEmailRQ',
      copy,
      credentials: {loginkey: this.credentialService.getLoginKey}
    };
    
    this.postRequest(data);
  }

  sendGuestStatementEmail(emailAddresses: string, clientid: number, copy: boolean, totalresults: boolean) {
    const data = {
      emailaddresses: emailAddresses.split(',').filter(str => !!str).map(str => str.trim()),
      clientid: clientid,
      template: "guest_statement",
      messagename: 'DocumentTemplateEmailRQ',
      emailestablishment: copy,
      totalresults,
      credentials: {loginkey: this.credentialService.getLoginKey}
    };

    this.postRequest(data);
  }

  postRequest(data) {
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));

    this.http.post<any>('/bridgeitapi', body).subscribe(response => {
      if (response.success) {
        if (this.isAltComponentDisplayActive) {
          this.cuiToastService.show(
            'Success',
            'Email sent successfully',
            {classname: 'toast-success', delay: 4000, positionClass: 'toast-bottom-right'});

          return;
        }

        this.toastr.success(
          'Email sent successfully',
          'Success',
          {timeOut: 30000, extendedTimeOut: 0, closeButton: true}
        );
      }
    });
  }

  setCopyValue(value: boolean) {
    this.copy = value;
  }

  getCopyValue(): boolean {
    return this.copy;
  }

  private get isAltComponentDisplayActive() {
    const user = this.credentialService.getCurrentUser.value;
    return user.altComponentDisplayActive;
  }
}
