<app-tap-n-pay-modal-container title="Do you really want to close Tap & Pay?">
  <div class="d-flex justify-content-center my-1">
    <img class="h-100" src="../../../assets/images/icons/info-error.svg"/>
  </div>
  <div class="w-360 text-center fs-16">
    <div class="wrap-text">
      <p>The transaction will continue to be processed in the background. <p>
    </div>
  </div>
</app-tap-n-pay-modal-container>
<div class="d-flex modal-footer border-top justify-content-between">
  <cui-button class="d-flex flex-1" (onClick)="reject()" label="No, go back" name="cancel" classes="tap-n-pay-btn-cancel tap-n-pay-btn-action-override" mode="light"></cui-button>
  <cui-button class="d-flex flex-1" (onClick)="accept()" label="Yes, close it" name="refund" classes="tap-n-pay-btn-success tap-n-pay-btn-action-override" mode="light"></cui-button>
</div>
