import {NgModule} from '@angular/core';
import {RouterLinkActive, RouterLinkWithHref, RouterModule} from "@angular/router";
import {DaterangePipe} from './daterange-selector/daterange.pipe';
import {NotesComponent} from '@cui/notes/notes.component';
import {LineCountDirective} from '@cui/notes/line-count.directive';
import {HeaderComponent} from './header/header.component';
import {IndeterminateProgressbarComponent} from './indeterminate-progressbar/indeterminate-progressbar.component';
import {ToastsComponent} from './toasts/toasts.component';
import {CoreModule} from "@core/core.module";

import {
  DateSelectorComponent,
  MonthSelectorComponent,
  PaymentStatusPillComponent,
  PaymentStatusSymbolComponent,
  DaterangeSelectorComponent,
  InfoBarComponent,
  TotalPillComponent,
  TableComponent,
  ButtonComponent,
  NavTabsComponent,
  TabbedPageComponent,
  AccordionComponent,
  AccordionActionsComponent,
  AccordionSummaryComponent,
  AccordionDetailsComponent,
  LogoutModalComponent,
  BreadcrumbsComponent
} from '@cui/index';

@NgModule({
  imports: [
    CoreModule,
    RouterModule,
    RouterLinkWithHref,
    RouterLinkActive
  ],
  declarations: [
    DaterangePipe,
    DaterangeSelectorComponent,
    MonthSelectorComponent,
    DateSelectorComponent,
    TotalPillComponent,
    InfoBarComponent,
    PaymentStatusPillComponent,
    PaymentStatusSymbolComponent,
    HeaderComponent,
    IndeterminateProgressbarComponent,
    NotesComponent,
    LineCountDirective,
    ToastsComponent,
    TableComponent,
    ButtonComponent,
    NavTabsComponent,
    TabbedPageComponent,
    AccordionComponent,
    AccordionSummaryComponent,
    AccordionDetailsComponent,
    AccordionActionsComponent,
    InfoBarComponent,
    LogoutModalComponent,
    BreadcrumbsComponent,
  ],
  exports: [
    CoreModule,
    DaterangePipe,
    DaterangeSelectorComponent,
    MonthSelectorComponent,
    DateSelectorComponent,
    TotalPillComponent,
    InfoBarComponent,
    PaymentStatusPillComponent,
    PaymentStatusSymbolComponent,
    ToastsComponent,
    TableComponent,
    ButtonComponent,
    NavTabsComponent,
    HeaderComponent,
    IndeterminateProgressbarComponent,
    NotesComponent,
    TabbedPageComponent,
    AccordionComponent,
    AccordionSummaryComponent,
    AccordionDetailsComponent,
    AccordionActionsComponent,
    BreadcrumbsComponent
  ]
})
export class CuiModule { }
