<div class="createStatementContainer">
    <div class="modal-header justify-content-between">
        <span></span>
        <div class="text-center">
            <label class="typography__h3">
                {{'create-statement' | translate: 'createStatementTitle'}}
            </label>
        </div>
        <div class="close-modal" (click)="back()">
            <span class="helper"></span>
            <img src="assets/images/close.svg" class="display__end" />
        </div>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <label class="transactionHeading">
                    <label>{{"create-statement" | translate: "transactionsInclusion"}}:</label>
                </label>
                <br>
                <div class="selector-container">
                    <label>
                        <input type="radio" name="transactionType" value="TOTAL" [(ngModel)]="selectedType" />
                        <label class="radio-messages">{{"create-statement" | translate: "showAll"}}</label>
                    </label>
                    <label>
                        <input type="radio" name="transactionType" value="OUT" [(ngModel)]="selectedType" />
                        <label class="radio-messages">{{"create-statement" | translate: "showOutstanding"}}</label>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-end pe-4">
        <button class="btn__regular btn btn__primary me-2" (click)="downloadStatement()">
            <span>
                {{"confirmation" | translate:'downloadExcel'}}
            </span>
        </button>
        <button class="btn__regular btn btn__primary" (click)="downloadPDFStatement()">
            <span>
                {{"confirmation" | translate:'viewStatement'}}
            </span>
        </button>
    </div>
</div>
