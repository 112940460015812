import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment/moment';

import {TranslateService} from 'src/app/service/translate.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {PageRouteDataService} from 'src/app/service/page-route-data.service';
import {ExtraService} from 'src/app/service/extra.service';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {Extra} from '../../../service/models/Extra';
import {Stock} from '../../../service/models/Stock';
import {CurrencyPipe} from "../../../service/currency.pipe";
import {NbLibraryService} from "../../../service/nb-library.service";
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-extra',
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.scss'],
  providers: [CurrencyPipe]
})
export class ExtraComponent implements OnInit {
  @Input() bookingid;

  @Input() options;
  extra: Extra;
  stock: Stock;
  accountinformation: {[key: string]: any} = {};
  stocklist = [];
  extraid;
  isSpecialAccount;
  saveClicked = false;
  extraForm;
  accountChange: boolean = false;

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private pageRouteService: PageRouteService,
    private pageRouteDataService: PageRouteDataService,
    public extraService: ExtraService,
    private datelibraryService: DatelibraryService,
    private nbLib: NbLibraryService,
    private currencyPipe: CurrencyPipe
  ) {
  }

  ngOnInit(): void {
    if (!this.options) {
      this.close();
    }
    this.extra = new Extra();
    this.extraid = this.options.extraid || 0;

    if (this.extraid > 0) {
      this.extraService.newextra = false;
    } else {
      this.extraService.newextra = true;
    }

    this.extraForm = new FormGroup({
      stock: new FormControl(undefined), // stock
      qty: new FormControl(undefined, Validators.pattern(/^\d+$/)), // extra.qty
      price: new FormControl(undefined, Validators.pattern(/^[-]?[0-9.\s,]*$/)), // stock.price
      extradate: new FormControl(undefined, Validators.required), // extra.extradate
    });

    this.extraForm.valueChanges.subscribe(() => {
      this.debounce(this.getFormValues)();
    });

    this.getData();
  }

  close() {
    this.pageRouteService.back();
  }

  getData() {
    this.extraService.getExtraData(this.extraid, this.options.guestid, this.bookingid).subscribe((response: any) => {
      this.stocklist = response.data.stocklist;
      this.stocklist.unshift({
        stockname: this.translateService.translate('extra', 'pleaseSelect'),
        price: undefined,
        bbstockid: 0
      });
      this.extra = response.data.extra;
      this.extra.guestid = this.options.guestid;
      this.accountinformation = response.data.accountinformation;
      this.stocklist.forEach((stock) => {
        if (this.extra.bbstockid === stock.bbstockid) {
          this.stock = JSON.parse(JSON.stringify(stock));
        }
      });

      if (this.extra.extraid === 0) {
        // new extra

        this.extra.extradate = this.getExtraDate();
        this.stock = this.stocklist[0];
        this.extra.qty = 1;

        if (this.accountinformation.bookingbbaccountclosed) {
          this.extra.bbaccountid = this.accountinformation.guestbbaccountid;
        } else if (response.data.accountinformation.hasmorethanoneguest) {
          this.extra.bbaccountid = response.data.accountinformation.guestbbaccountid;
          this.extra.accountinformation = this.extra.accountinformation || {};
          this.extra.accountinformation.guestbbaccountid = response.data.accountinformation.guestbbaccountid;
        } else {
          this.extra.bbaccountid = response.data.accountinformation.bookingbbaccountid;
        }
      } else if (this.accountinformation.bookingbbaccountclosed) {
        this.extra.bbaccountid = this.accountinformation.guestbbaccountid;
      } else {
        this.stock = this.stocklist.find(stock => stock.bbstockid === this.stock.bbstockid);
      }

      if (this.extra.price !== '' && this.extra.price !== 0) {
        this.stock.price = this.extra.price;
      }

      if (this.accountinformation.bookingbbaccountid === this.accountinformation.guestbbaccountid) {
        this.accountinformation.guestbbaccountid = 0;
      }

      this.setAccountDisplay();
      this.formatNumberForDisplay();
      this.setFormValues();
    });
  }

  deleteExtra() {
    const deleteExtra = (reason) => {
      this.extraService.deleteExtra(this.extraid, reason).subscribe((response) => {
        if (response.success === false) {
          this.confirmationService.show({
            title: 'alertReported',
            text: response.error.message,
            buttons: [{
              text: 'ok',
              callback: () => { }
            }]
          });
        } else {
          this.close();
        }
      });
    };

    this.confirmationService.prompt({
      title: 'provideExtraDelete',
      callback: deleteExtra,
      buttons: [{
        text: 'confirm',
        class: 'btn-cancel',
        callback: () => { }
      }]
    });
  }

  persistData() {
    if (!this.saveClicked) {
      this.saveClicked = true;

      if (this.stock.bbstockid === 0) {
        this.confirmationService.show({
          title: 'alertReported',
          text: this.translateService.translate('extra', 'selectStockItem'),
          buttons: [{
            text: 'ok',
            callback: () => {
            }
          }]
        });
        this.saveClicked = false;
      } else if (this.extra.qty === 0) {
        this.confirmationService.show({
          title: 'alertReported',
          text: this.translateService.translate('extra', 'atLeastOneExtraQty'),
          buttons: [{
            text: 'ok',
            callback: () => {
            }
          }]
        });
        this.saveClicked = false;
      } else if (this.extraForm.pristine) {
        this.close();
      } else if (this.extraForm.valid || this.accountChange) {
        // this.extra.price = this.stock.price;
        this.saveExtra();
      } else {
        this.saveClicked = false;
      }
    }
  }

  formatNumberForDisplay() {
    if (this.extra.qty) {
      this.extra.qty = Math.round(this.extra.qty.toString().split(',').join('.') * 100) / 100;
    } else {
      this.extra.qty = 1; //default 1
    }
  }

  getExtraDate() {
    const today = this.datelibraryService.getDate();
    let date;

    if (this.datelibraryService.daysDiff(this.datelibraryService.getDateObject(today),
      this.datelibraryService.getDateObject(this.options.bookingfromdate)) > 0) {
      date = this.options.bookingfromdate;
    } else if (this.datelibraryService.daysDiff(this.datelibraryService.getDateObject(today),
      this.datelibraryService.getDateObject(this.options.bookingtodate)) < 0) {
      date = this.options.bookingtodate;
    } else {
      date = today;
    }

    return this.datelibraryService.getDateObject(date);
  }

  setAccountDisplay() {
    this.isSpecialAccount = (
      this.extra.bbaccountid > 0 &&
      this.extra.bbaccountid !== this.accountinformation.bookingbbaccountid &&
      this.extra.bbaccountid !== this.accountinformation.guestbbaccountid
    );
  }

  saveExtra() {
    this.extraService.saveExtra(this.getExtraData(), this.extraForm.value.stock).subscribe(() => {
      this.close();
    });
  }

  getExtraData() {
    let displayPrice = String(this.extraForm.get('price').value);
    let price = Number(this.nbLib.getNumberFromCurrency(displayPrice));
    return {
      ...this.extra,
      price: price
    }
  }

  setFormValues() {
    this.extraForm.patchValue({
      guestbbaccountid: !!this.accountinformation.guestbbaccountid,
      stock: this.stock.bbstockid,
      qty: this.extra.qty,
      price: this.currencyPipe.transform(this.stock.price) === undefined ? null : this.currencyPipe.transform(this.stock.price),
      extradate: this.datelibraryService.getDateObject(this.extra.extradate),
    });
  }

  getFormValues() {
    this.stock = JSON.parse(JSON.stringify(this.extraForm.value.stock));
    this.extra.qty = JSON.parse(JSON.stringify(this.extraForm.value.qty));
    if (this.stock === undefined || this.stock === null) {
      this.stock = new Stock();
    }

    if (this.stock && this.stock.price) {
      this.stock.price = this.extraForm.value.price === undefined ? 0 : JSON.parse(JSON.stringify(this.extraForm.value.price));
    }

    this.extra.extradate = this.extraForm.value.extradate
      ? moment(this.extraForm.value.extradate).format('YYYY-MM-DD')
      : this.getExtraDate();
  }

  updatePrice(event) {
    let stockid = event.target?.value ? event.target.value : event;
    let stock = this.stocklist.find((stock) => {return stock.bbstockid == stockid});
    this.extraForm.get('stock').patchValue(stock.bbstockid);
    this.extraForm.get('price').patchValue(this.currencyPipe.transform(stock.price));
    this.stock = stock;
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }

  invoiceToChange() {
    this.extraForm.markAsDirty();
    this.accountChange = true;
  }

  formatPrice() {
    this.extraForm.get('price').patchValue(this.currencyPipe.transform(this.extraForm.get('price').value));
  }
}
