import {Component, Input} from '@angular/core';
import {TapNpayService} from './tap-n-pay.service';
import {TapNpayBookingDetails, TapNpayPaymentDetails } from "./tap-n-pay.class";

@Component({
    selector: 'app-tap-n-pay',
    styles: [`
        ::ng-deep .tap-n-pay-button {
            background-color: var(--cui-white) !important;
            color: var(--cui-primary) !important;
            border: 1px solid var(--cui-primary);

            &:hover {
                background-color: var(--cui-primary) !important;
                color: var(--cui-white) !important;
                border: 0.5px solid var(--cui-primary) !important;

                .leading-icon {
                    color: var(--cui-white) !important;
                }
            }
        }
    `],
    template: `
      <cui-button (onClick)="initializeTapNpay()" [type]="'outlined'" [disabled]="disabled" [leadingIcon]="disabled ? undefined : 'rfid'" [classes]="classes + ' tap-n-pay-button tap-n-pay-button-override'">
        <span class="fs-14">
            {{ disabled ? 'Set up your Tap & Pay' : 'Tap & Pay' }}
        </span>
      </cui-button>
    `
})
export class TapNpayComponent {

    @Input() disabled = false;
    @Input() classes = '';
    @Input() bookingDetails?: TapNpayBookingDetails;
    @Input() details: TapNpayPaymentDetails = {bbId: 0, amount: 0, bookingId: 0, mode: 'payment', refundPaymentId: ''};

    constructor(private tapNpayService: TapNpayService) {}

    initializeTapNpay() {
        const accommodationTotal = this.tapNpayService.sanitizeBookingAmount(this.bookingDetails?.accommodationTotal);
        const extraTotal = this.tapNpayService.sanitizeBookingAmount(this.bookingDetails?.extraTotal);
        const paymentsTotal = this.tapNpayService.sanitizeBookingAmount(this.bookingDetails?.paymentsTotal);

        let amountDue = 0;
        const {amount, bbId, bookingId, mode, refundPaymentId} = this.details;

        let fromDate = new Date().toString();
        let toDate = new Date().toString();
        let clientFullName = undefined;

        if (this.bookingDetails) {
          amountDue = Math.round(accommodationTotal + extraTotal - paymentsTotal);
          fromDate = this.bookingDetails.fromDate;
          toDate = this.bookingDetails.toDate;
          clientFullName = this.bookingDetails.clientFullName;
        }

        this.tapNpayService.startTapNPay({
          paymentDetails: {amount, bbId, bookingId, mode, refundPaymentId},
          bookingDetails: {amountDue, accommodationTotal, extraTotal, paymentsTotal, fromDate, toDate, clientFullName}
        })
    }



}
