import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ClipboardService} from 'ngx-clipboard';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {DisplayManagerService} from 'src/app/service/display-manager.service';
import {LoadingService} from 'src/app/service/loading.service';
import {Constants} from 'src/app/service/models/enum/constants.enum';
import {AccountService} from '../../../service/account.service';
import {ConfirmationService} from '../../../service/confirmation.service';
import {CredentialService} from "../../../service/credential.service";
import {GuestCommunicationsService} from '../../../service/guest-communications.service';
import {ModalPopupService} from '../../../service/modal-popup.service';
import {Account} from '../../../service/models/Account';
import {UserType} from '../../../service/models/enum/user-type.enum';
import {Items} from "../../../service/models/Items";
import {breakpoints} from "../../../service/models/mediaBreakpoints";
import {NbLibraryService} from '../../../service/nb-library.service';
import {PageRouteDataService} from '../../../service/page-route-data.service';
import {PageRouteService} from '../../../service/page-route.service';
import {PreferencesService} from "../../../service/preferences.service";
import {TranslateService} from '../../../service/translate.service';

@Component({
  selector: 'app-accountsdetail',
  templateUrl: './accountsdetail.component.html',
  styleUrls: ['./accountsdetail.component.scss'],
})

export class AccountsDetailComponent implements OnInit {
  @Input() options;
  casEnabled: boolean;
  pageData;
  returnData;
  pdfViewerActive;
  accountId: number;
  client: {[key: string]: any} = {};
  account: Account;
  items = [];
  statement = true;
  newInvoiceToClient;
  invoicetochanged = false;
  paymentmade = false;
  invoiceNumber: number = 0;
  invoiceTemplatePreference: string;
  constants;
  user;
  userTypes;
  breakpoints

  constructor(
    private accountService: AccountService,
    public translateService: TranslateService,
    private pageRouteService: PageRouteService,
    private _router: Router,
    private confirmationService: ConfirmationService,
    private modalPopUpService: ModalPopupService,
    private activeModal: NgbActiveModal,
    private guestCommmunicationService: GuestCommunicationsService,
    private pageRouteDataService: PageRouteDataService,
    private datelibraryService: DatelibraryService,
    private clipboardService: ClipboardService,
    private nbLib: NbLibraryService,
    private modalPopup: ModalPopupService,
    private preferencesService: PreferencesService,
    private authService: CredentialService,
    private displayManagerService: DisplayManagerService,
    private loadingService: LoadingService
  ) {
    this.constants = Constants;
    this.userTypes = UserType;
    this.breakpoints = breakpoints;
  }

  ngOnInit(): void {
    this.pageData = this.pageRouteDataService.getData();
    this.returnData = this.pageRouteDataService.getReturnData();
    this.paymentmade = this.returnData === undefined ? false : this.returnData.paymentmade;
    this.account = new Account();

    if (!this.accountId) {
      if (this.options?.accountId) {
        this.accountId = this.options?.accountId;
      } else {
        const urlSegments = this._router.url.split('/');
        this.accountId = Number(urlSegments[3]);
      }
    }

    this.loadAccount().then(() => {
      this.mergeNavigationState();
      this.handleReturnData();
    });

    this.user = this.authService.getCurrentUser.value;
    this.pdfViewerActive = this.user.pdfViewerActive;
    this.preferencesService.getPreferences().subscribe((response: any) => {
      this.invoiceTemplatePreference = response.data.preferences.useexcelformatforinvoices ? Constants.EXCEL : response.data.preferences.usepdfformatforinvoices ? Constants.PDF : Constants.NOSELECTION;
    });
  }

  loadAccount() {
    return new Promise((resolve) => {
      this.accountService.getAccountSummary(this.accountId).subscribe((response: any) => {
        this.client = response.data.client;
        this.client.fullname = this.accountService.getClientFullName(this.client);
        this.account = response.data.account;
        this.items = response.data.items;
        this.statement = response.data.statement;

        this.account.accomtotal = 0.00;
        this.account.extratotal = 0.00;
        this.account.paymenttotal = 0.00;
        this.account.outstanding = 0.00;
        this.account.invoiceno = this.account.invoiceno === undefined ? this.invoiceNumber : this.account.invoiceno;
        this.invoiceNumber = this.account.invoiceno === 0 ? this.invoiceNumber : this.account.invoiceno;
        this.account.proformano = this.account.proformano === 0 ? '' : this.account.proformano;

        this.setStatusDisplay();
        this.calculateItems();
        this.account.outstanding = this.accountService.calculateTotals(this.account);
        this.account.outstanding = this.account.outstanding;
        this.account.accomtotal = this.account.accomtotal;
        this.account.extratotal = this.account.extratotal;
        this.account.paymenttotal = this.account.paymenttotal;

        this.isAccountSettled();

        resolve(true);
      });
    });
  }

  back(returnData?) {
    this.pageRouteService.back(returnData);
  }

  close() {
    this.activeModal.close();
  }

  getLocalDate(date) {
    return this.datelibraryService.getLocalDate(date);
  }

  editClient() {
    this.pageRouteService.navigate(this.getCurrentUrl(), {
      ...this.getNavigationState(),
      onClose: 'f_editClient',
    }, ['/clients/edit', this.client.clientid], {
      clientid: this.client.clientid,
    });
  }

  setStatusDisplay() {
    if (this.account.status === Constants.ACCOUNT_OPEN) {
      this.account.statusdisplay = this.translateService.translate('accountSummary', 'isOpen');
    } else {
      this.account.statusdisplay = this.translateService.translate('accountSummary', 'isClosed');
    }
  }

  calculateItems() {

    this.account = this.accountService.calculateItems(this.items, this.account);
    this.items.forEach((item) => {
      item.price = item.price || '-';
      item.debit = item.debit || '-';
      item.credit = item.credit || '-';
    });
  }


  isAccountSettled() {
    if (this.account.status === Constants.ACCOUNT_OPEN) {
      if (Math.round(this.account.outstanding * 100) / 100 === 0.0) {
        this.confirmationService.show({
          title: 'confirmation',
          text: this.translateService.translate('accountSummary', 'accountAutoInvoice'),
          buttons: [{
            text: 'no',
            class: 'btn__warning',
            wrappingClass: 'flex-1',
            callback: () => {
            }
          }, {
            text: 'yes',
            class: 'btn__green',
            wrappingClass: 'flex-1',
            callback: () => {
              this.generateInvoice(this.invoiceTemplatePreference === Constants.PDF);
            }
          }]
        });
      }
    }
  }

  getCurrentUrl() {
    return 'account-search/account-detail/' + this.account.accountid;
  }

  changeClient() {
    if (this.account.status === Constants.ACCOUNT_OPEN) {
      this.pageRouteService.navigate(this.getCurrentUrl(), {
        ...this.getNavigationState(),
        onClose: 'f_changeClient',
      }, ['/clients/search'], {
        mode: 2,
      });
    }
  }

  updateAccountStatus() {
    if (this.account.status === Constants.ACCOUNT_OPEN) {
      this.confirmationService.show({
        title: 'confirmation',
        text: this.translateService.translate('accountSummary', 'accountCloseMessage'),
        buttons: [{
          text: 'dontCloseAccount',
          class: 'btn__warning',
          wrappingClass: 'flex-1',
          callback: () => {
          }
        }, {
          text: 'closeAccount',
          class: 'btn__green',
          wrappingClass: 'flex-1',
          callback: () => {
            this.loadingService.showLoadingScreen();
            this.accountService.closeAccountAgain(this.account.accountid).subscribe((response: any) => {
              this.loadingService.hideLoadingScreen();
              this.account.status = Constants.ACCOUNT_CLOSED;
              this.setStatusDisplay();
              this.loadAccount();
            });
          }
        }]
      });
    } else {
      this.confirmationService.show({
        title: 'confirmation',
        text: this.translateService.translate('accountSummary', 'accountOpenMessage'),
        buttons: [{
          text: 'cancel',
          class: 'btn__warning',
          callback: () => {
          }
        }, {
          text: 'openAccount',
          class: 'btn__green',
          callback: () => {
            this.loadingService.showLoadingScreen();
            this.accountService.openAccountAgain(this.account.accountid).subscribe((response: any) => {
              this.loadingService.hideLoadingScreen();
              if (response.data.accountreopened === 1) {
                this.account.invoiceno = 0;
                this.account.status = Constants.ACCOUNT_OPEN;
                this.setStatusDisplay();
                this.loadAccount();
              }
            });
          }
        }]
      });
    }
  }

  createProForma() {
    if (!this.account.proformano) {
      this.accountService.generateProForma(this.accountId).subscribe((response: any) => {
        this.account.proformano = response.data.proformano;
        this.openHTMLViewer('proforma');
      });
    } else {
      this.openHTMLViewer('proforma');
    }
  }

  downloadProForma() {
    if (!this.account.proformano) {
      this.accountService.generateProForma(this.accountId).subscribe((response: any) => {
        this.account.proformano = response.data.proformano;
        this.accountService.openDocumentLink('/PROFORMA/' + response.data.proformano);
      });
    } else {
      this.accountService.openDocumentLink('/PROFORMA/' + this.account.proformano);
    }
  }

  /*
  * Opens the invoice/proforma/creditnote html viewer
  * params: template - "invoice" / "proforma" / "creditnote"
  */
  openHTMLViewer(template) {
    let templateno;
    if (template === 'proforma') {
      templateno = this.account.proformano;
    } else {
      templateno = this.account.invoiceno;
    }
    this.pageRouteService.navigate('/account-search/account-detail/' + this.accountId, {}, ['/account-search/account-detail/' + this.accountId + '/' + template + '/' + templateno], {
      template: template,
      templateno: templateno,
      proformano: this.account.proformano,
      email: this.client.email,
      clientid: this.client.clientid,
      invoiceno: this.account.invoiceno,
      accountid: this.accountId,
      firstname: this.client.firstname,
      fullname: this.client.fullname,
      bbid: this.account.bbid
    });
  }

  closeModal() {

  }

  payOnCheckOut() {
    this.modalPopup.openAccountCheckout({
      account: this.account,
      client: this.client,
      overlay: true
    });
  }

  createInvoice(htmlViewer: boolean) {
    if (this.account.invoiceno) {
      this.invoiceReprintWarning(true);
    } else {
      if (this.account.status === Constants.ACCOUNT_CLOSED) {
        this.confirmationService.show({
          title: 'confirmation',
          text: this.translateService.translate('accountSummary', 'accountCloseInvoiceMessage'),
          buttons: [{
            text: 'ok',
            class: 'btn__primary',
            callback: () => {
            }
          }
          ]
        });
      } else {
        this.confirmationService.show({
          title: 'confirmation',
          text: this.translateService.translate('accountSummary', 'confirmInvoiceGenerate'),
          buttons: [{
            text: 'notNow',
            class: 'btn__warning',
            wrappingClass: 'flex-1',
            callback: () => {
            }
          }, {
            text: 'continue',
            class: 'btn__green',
            wrappingClass: 'flex-1',
            callback: () => {
              this.generateInvoice(htmlViewer);
            }
          }]
        });
      }
    }
  }

  invoiceReprintWarning(bool) {
    this.confirmationService.show({
      title: 'confirmation',
      text: this.translateService.translate('accountSummary', 'accountReprintWarning'),
      footerFlexboxAlignment: 'between',
      buttons: [{
        text: 'openAccount',
        class: 'btn__warning',
        wrappingClass: 'flex-1',
        callback: () => {
          this.accountService.openAccountAgain(this.account.accountid).subscribe((response: any) => {
            if (response.data.accountreopened === 1) {
              this.account.invoiceno = 0;
              this.account.status = Constants.ACCOUNT_OPEN;
              this.setStatusDisplay();
            }
          });
        }
      }, {
        text: 'print',
        class: 'btn__green',
        wrappingClass: 'flex-1',
        callback: () => {
          this.confirmationService.close();
          if (bool) {
            this.openHTMLViewer('invoice');
          } else {
            this.viewInvoice();
          }
        }
      }]
    });
  }

  generateInvoice(htmlViewer) {
    this.accountService.generateInvoice(this.accountId).subscribe((response: any) => {
      this.invoiceNumber = response.data.invoiceno;
      this.account.invoiceno = this.invoiceNumber;
      this.account.status = Constants.ACCOUNT_CLOSED;
      this.setStatusDisplay();
      if (htmlViewer) {
        this.openHTMLViewer('invoice');
      } else {
        this.accountService.openDocumentLink('/INVOICE/' + response.data.invoiceno);
      }
    });
  }

  viewInvoice() {
    this.accountService.openDocumentLink('/INVOICE/' + this.account.invoiceno);
  }

  addPayment() {
    this.pageRouteService.navigate(this.getCurrentUrl(), {
      ...this.getNavigationState(),
      onClose: 'this.loadAccount',
    }, ['/payment'], {
      accountid: this.accountId,
      paymentmade: true
    });

    this.paymentmade = true;
  }

  generateCcLink() {
    this.accountService.createCreditLink(this.client.clientid, this.account.bbaccountid, true).subscribe(response => {
      const responseCallback = (response: any) => {
        if (response.data.creditlink.valid) {
          this.confirmationService.show({
            title: 'guestLink',
            text: response.data.creditlink.message,
            iconVisible: false,
            buttons: [{
              text: 'copy',
              class: 'btn__outlined',
              callback: () => {
                this.clipboardService.copy(response.data.creditlink.message);
                this.confirmationService.show({
                  title: 'confirmation',
                  text: this.translateService.translate('payment', 'paymentLinkCopySuccess'),
                  iconVisible: false,
                  buttons: [{
                    text: 'ok',
                    callback: () => { }
                  }]
                });
              }
            }, {
              text: 'open',
              class: 'btn__primary',
              callback: () => {
                window.open(response.data.creditlink.message, '_blank');
              }
            }]
          });
        } else {
          this.confirmationService.show({
            title: 'alertReported',
            text: response.data.creditlink.message,
            iconVisible: true,
            buttons: [{
              text: 'ok',
              class: 'btn__primary',
              callback: () => { }
            }]
          });
        }
      };

      if (!response.data.creditlink.valid) {
        responseCallback(response);
      } else {
        if (!this.user.casEnabled) {
          this.confirmationService.show({
            title: 'createPaymentLink',
            text: `<span class="fw-semibold">Create Credit Card Link with 3D secure enabled?</span>`,
            iconVisible: false,
            buttons: [{
              text: 'no',
              class: 'btn__warning',
              wrappingClass: 'flex-1',
              callback: () => {
                this.confirmationService.close();
                setTimeout(() => {
                  this.confirmationService.show({
                    title: 'createPaymentLink',
                    text: `<span class="fw-semibold">We highly recommend using the secure 3D link to protect your property against chargebacks and disputes.</span> \n\n <span class="fs-14 fw-normal">Choosing the non-3D link offers less security and makes you vulnerable to fraud.</span>`,
                    iconVisible: true,
                    buttons: [{
                      text: 'non3DLink',
                      class: 'btn__warning',
                      wrappingClass: 'flex-1',
                      callback: () => {
                        this.accountService.createCreditLink(
                          this.client.clientid,
                          this.account.bbaccountid,
                          false,
                        ).subscribe(responseCallback);
                      }
                    },
                    {
                      text: 'is3DLink',
                      class: 'btn__green',
                      wrappingClass: 'flex-1',
                      callback: () => {
                        this.accountService.createCreditLink(
                          this.client.clientid,
                          this.account.bbaccountid,
                          true
                        ).subscribe(responseCallback);
                      }
                    }]
                  });
                }, 100);
              }
            }, {
              text: 'yes',
              class: 'btn__green',
              wrappingClass: 'flex-1',
              callback: () => {
                this.accountService.createCreditLink(
                  this.client.clientid,
                  this.account.bbaccountid,
                  true
                ).subscribe(responseCallback);
              }
            }]
          });
        } else {
          responseCallback(response);
        }
      }
    });
  }


  viewItem(item) {

    if (item.itemtype === Constants.LINE_ITEM_CAPS_EXTRA) {
      return;
    }

    const f_setClientAccountFlags = (items, chosenItem) => {
      // Return false IF only one item in list or only one booking.
      if (items.length === 1) {
        return false;
      }
      // Can't move this item
      if (chosenItem.itemtype === 'X') {
        return true;
      } // If it's not a booking then you can move it.
      let i;
      let bookingCount = 0;
      for (i in items) {
        if (items[i].itemtype === 'B') {
          bookingCount++;
        }
      }
      return bookingCount > 1; // If there's more than one booking you can move it.
    };

    if (this.account.status !== Constants.ACCOUNT_OPEN) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('accountSummary', 'accountClosedItem'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
      return;
    }

    if (item.itemtype === Constants.LINE_ITEM_PAYMENT) {
      this.pageRouteService.navigate(this.getCurrentUrl(), {
        ...this.getNavigationState(),
        onClose: 'updateTransaction',
        onCloseParameter: item,
      }, ['/payment/payment-actions'], {
        ...this.getNavigationState(),
        item: item,
      });
    } else {
      this.pageRouteService.navigate(this.getCurrentUrl(), {
        ...this.getNavigationState(),
        onClose: 'updateLineItems',
      }, ['/client-account'], {
        id: item.itemid,
        type: item.itemtype,
        showNewAccount: f_setClientAccountFlags(this.items, item),
        accountid: this.accountId
      });
    }
  }

  getToolTipForAccountItem(item: Items) {
    if (item.itemtype === Constants.LINE_ITEM_PAYMENT) {
      return this.translateService.translate('accountSummary', 'lineItemPaymentTooltip');
    } else if (item.itemtype === Constants.LINE_ITEM_CAPS_EXTRA) {
      return this.translateService.translate('accountSummary', 'lineItemCapsExtraTooltip');
    } else {
      return this.translateService.translate('accountSummary', 'lineItemTooltip');
    }
  }

  generateStatement() {
    if (!this.statement) {
      this.confirmationService.show({
        title: 'alertReported',
        text: this.translateService.translate('accountSummary', 'noTransactions'),
        buttons: [{
          text: 'ok',
          callback: () => {
          }
        }]
      });
    } else {
      this.accountService.openStatement(this.account.invoiceto);
    }
  }

  saveToDB() {
    this.accountService.saveAccount(this.account).subscribe((response: any) => {
      this.back({
        paymenttotal: this.account.paymenttotal,
        outstanding: this.account.outstanding,
        paymentmade: this.paymentmade,
        newInvoiceToClient: this.newInvoiceToClient
      });
    });
  }

  getNavigationState() {
    return {
      client: this.client,
      account: this.account,
      items: this.items,
    };
  }

  mergeNavigationState() {
    if (!this.pageData || !this.pageData.account) {
      return;
    }

    this.account.reference = this.pageData.account.reference;
  }

  handleReturnData() {
    if (!this.returnData || !this.pageData?.onClose) {
      return;
    }

    const isValidClient = (client) => {
      return Boolean(
        client &&
        typeof client.clientid === 'number' &&
        client.clientid !== 0
      );
    };

    const f_editClient = (client) => {
      this.client = client;
    };

    const f_changeClient = (client) => {
      if (!isValidClient(client)) return;
      this.client = client;
      this.account.invoiceto = client.clientid;
      this.newInvoiceToClient = client;
      this.invoicetochanged = true;
    };

    const updateTransaction = (item, reference) => {
      item.description = 'Payment - ' + reference;
    };

    const updateLineItems = (data) => {
      if (data.accountid !== this.accountId) {
        this.accountId = data.accountid;
      }
      this.loadAccount(); // This effectively takes the user to the new account or stays where we are
    };

    // find out from which function we were redirected
    let onClose;
    switch (this.pageData.onClose) {
      case 'f_editClient':
        onClose = f_editClient;
        break;
      case 'f_changeClient':
        onClose = f_changeClient;
        break;
      case 'this.loadAccount':
        onClose = this.loadAccount;
        break;
      case 'updateTransaction':
        onClose = updateTransaction.bind(this, this.pageData.onCloseParameter);
        break;
      case 'updateLineItems':
        onClose = updateLineItems;
        break;
    }

    if (onClose) {
      onClose.bind(this)(this.returnData);
    }
  }

  getAmount(amount: any) {
    if (+amount === 0) {
      return "-";
    }
    return amount;
  }

  openStatementModal() {
    this.pageRouteService.navigate(this.getCurrentUrl(), {
      ...this.options,
      onClose: 'closeModal',
    }, ['/account-search/create-statement/' + this.client.clientid], {
      clientid: this.client.clientid,
      email: this.client.email
    });
  }

  isMobile() {
    return this.displayManagerService.isMobile();
  }
}
