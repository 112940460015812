import {Component, Input} from '@angular/core';
import {interval, map, takeWhile} from "rxjs";

@Component({
  selector: 'app-tap-n-pay-modal-spinner',
  templateUrl: './tap-n-pay-modal-spinner.component.html',
  styleUrls: ['./tap-n-pay-modal-spinner.component.scss']
})
export class TapNpayModalSpinnerComponent {

  @Input() confirmBeforeClose: boolean = false;
  @Input() message: string = 'Loading...';
  @Input() timeoutAfterLabel: string = '';
  secondsLeft = 0;

  startTimer(seconds: number) {
    const timerInterval$ = interval(1000)
      .pipe(
        takeWhile(currentSeconds => currentSeconds <= seconds),
        map(currentSeconds => seconds - currentSeconds),
      ).subscribe((secondsLeft) => {
        this.secondsLeft = secondsLeft;
        if(secondsLeft === 0) {
          timerInterval$.unsubscribe();
        }
      });
  }

  secondsToMinutes(seconds: number | null) {
    if (!seconds) return '';

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

}
