import {Component, HostListener, ViewChild, ViewContainerRef} from '@angular/core';
import {Stack} from 'src/app/service/models/Stack';
import {PanelRef} from '../panel-ref';

@Component({
  selector: 'cui-panel-container',
  templateUrl: './panel-container.component.html',
  styleUrls: ['./panel-container.component.scss']
})
export class PanelContainerComponent {
  @ViewChild('panelHost', {read: ViewContainerRef}) panelHost: ViewContainerRef;
  private panels: Stack<PanelRef<any>> = new Stack<PanelRef<any>>();

  position: 'left' | 'right' = 'right';
  isFullScreen = false;

  openComponent(component: any, params?: any, config?: {position?: 'left' | 'right', isFullScreen?: boolean}): void {
    if (config.position) this.position = config.position;
    if (config.isFullScreen !== undefined) this.isFullScreen = config.isFullScreen;

    this.panelHost.clear();
    const componentRef = this.panelHost.createComponent(component);

    if (params) {
      for (const key of Object.keys(params)) {
        componentRef.instance[key] = params[key];
      }
    }

    const panelRef = new PanelRef(componentRef);
    panelRef.isOpen = true;
    this.panels.push(panelRef);
  }

  hasMultiplePanels(): boolean {
    return this.panels.hasMultiple();
  }

  closeTopPanel(): void {
    const panelRef = this.panels.pop();
    if (panelRef) {
      panelRef.isOpen = false;
      panelRef.componentRef.destroy();
    }
  }

  closeAllPanels(): void {
    while (!this.panels.isEmpty()) {
      this.closeTopPanel();
    }
  }

  get isAnyPanelOpen(): boolean {
    return this.panels.any(panel => panel.isOpen);
  }
}
