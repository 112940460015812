import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CheckOutComponent} from "../components/account-info/widgets/check-out/check-out.component";
import {GuestCommunicationComponent} from '../components/booking-info/widgets/guest-communication/guest-communication.component';
import {CheckoutPaymentComponent} from "../pages/booking-summary/checkout-payment/checkout-payment.component";
import {GuestCommunicationsComponent} from '../pages/booking-summary/guest-communications/guest-communications.component';
import {MultiRoomCommunicationComponent} from "../pages/booking-summary/guest-communications/multi-room-communication/multi-room-communication.component";
import {CredentialService} from './credential.service';
import {Account} from "./models/Account";
import {Client} from "./models/Client";
import {CheckInOutComponent} from "../pages/calendar/check-in-out/check-in-out.component";
import {NetworkAlertComponent} from '../shared/network-alert/network-alert.component';
import {PanelContainerService} from './panel-container.service';

type OnModalCloseOptions = 'close' | 'back' | 'refresh' | 'backAndClose';

export interface IGuestConfirmationOptions {
  chatallowed: any,
  guest: any,
  selectedGuest: any,
  guests: any,
  booking: any,
  clientiseditable: any,
  gmEnabled: any,
}
export interface ICheckoutOptions {
  account: Account,
  client: any,
  overlay: boolean,
  modalClose?: OnModalCloseOptions
}
export interface IMultiRoomRegistrationOptions {
  options: any
}

export interface IAccountCheckoutOptions {
  client: any,
  account: Account,
  overlay: boolean,
  modalClose?: OnModalCloseOptions
}

@Injectable({
  providedIn: 'root'
})
export class ModalPopupService {
  modalRef?: any;
  constructor(private modalService: NgbModal, private panelContainerService: PanelContainerService,
    private credentialService: CredentialService) { }

  openPopup(component: any, data: any) {
    this.modalRef = this.modalService.open(component, {centered: true, backdrop: 'static', modalDialogClass: 'modal-padding'});
    this.modalRef.componentInstance.data = data;
  }

  openBookingGuestDetails(confirmationOptions: IGuestConfirmationOptions) {
    if (this.isAltComponentDisplayActive) {
      this.panelContainerService.openResponsiveModal(GuestCommunicationComponent, confirmationOptions.booking.bookinId, confirmationOptions);
    } else {
      this.modalRef = this.modalService.open(GuestCommunicationsComponent, {
        centered: true
      });
      this.modalRef.componentInstance.options = confirmationOptions;
    }
  }

  openCheckOutDetails(checkoutOptions: ICheckoutOptions) {
    if (this.isAltComponentDisplayActive) {
      this.panelContainerService.openResponsiveModal(CheckOutComponent, checkoutOptions.account.bbid.toString(), checkoutOptions);
    } else {
      this.modalRef = this.modalService.open(CheckoutPaymentComponent, {
        centered: true
      });
      this.modalRef.componentInstance.options = checkoutOptions;
    }
  }

  openMultiRoomRegistration(multiRoomOptions: IMultiRoomRegistrationOptions) {
    this.modalRef = this.modalService.open(MultiRoomCommunicationComponent, {
      centered: true
    });
    this.modalRef.componentInstance.options = multiRoomOptions;
  }

  close() {
    this.modalRef.close();
  }

  openAccountCheckout(accountCheckoutOption: IAccountCheckoutOptions) {
    if (this.isAltComponentDisplayActive) {
      accountCheckoutOption.modalClose = 'refresh';
      this.panelContainerService.openResponsiveModal(CheckOutComponent, accountCheckoutOption.account.bbid.toString(), accountCheckoutOption);
    } else {
      this.modalRef = this.modalService.open(CheckoutPaymentComponent, {
        centered: true
      });
      this.modalRef.componentInstance.options = accountCheckoutOption;
    }
  }

  private get isAltComponentDisplayActive() {
    const user = this.credentialService.getCurrentUser.value;
    return user.altComponentDisplayActive;
  }
}
