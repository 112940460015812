<div class="clientAccountListContainer">
  <div class="modal-header d-flex justify-content-between">
    <span></span>
    <span class="typography__h3">
      {{'clientAccountList' | translate: 'accountList'}}
    </span>
    <div class="close-modal">
      <span class="helper"></span>
      <img src="assets/images/close.svg" (click)="close()" />
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-8">
        <label class=" inputField__label">Choose an account for {{client?.fullname}}</label>
      </div>
    </div>
    <div class="modal-body ">
      <div class="table_container__scrollable">
        <table class="table__bordered_style">
          <thead>
            <tr>
              <th class="col-md-2 typography__p"><span>{{ 'clientAccountList' | translate: 'acId' }}</span></th>
              <th class="col-md-9 typography__p"><span>{{ 'clientAccountList' | translate: 'accountDetails' }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let account of clientAccounts" (click)="moveToAccount(account)" [ngClass]="{'d-none': sameAccount(account)}">
              <td class="col-md-2 typography__p">
                <div><span>{{ account.bbaccountid }}</span></div>
              </td>
              <td class="col-md-9 typography__p">
                <div>
                  <span *ngIf="account.details.isbooking">BookingID - {{account.details.bbbookingid}} /
                    {{account.details.clientname}}
                    arriving {{account.details.fromdate | date:'dd/MM/yyyy'}} - {{account.details.todate | date:'dd/MM/yyyy'}}</span>
                  <span *ngIf="!account.details.isbooking">Extra - {{account.details.extradate}}
                    ({{account.details.qty}}x {{account.details.stockname}})</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>