import {Component, Input, OnInit} from "@angular/core";
import {AccountService} from "../../../service/account.service";
import {Account} from "../../../service/models/Account";
import {PageRouteDataService} from "../../../service/page-route-data.service";
import {PageRouteService} from "../../../service/page-route.service";
import {STATEMENT_TYPE} from '@core/models/enum';


@Component({
  selector: 'app-create-statement',
  templateUrl: './create-statement.component.html',
  styleUrls: ['./create-statement.component.scss'],
})

export class CreateStatementComponent implements OnInit {
  @Input() options;

  selectedType: string;
  account: Account;
  returnData;

  constructor(
    private accountService: AccountService,
    private pageRouteDataService: PageRouteDataService,
    private pageRouteService: PageRouteService,
  ) {
    this.selectedType = STATEMENT_TYPE.OUT;
  }
  ngOnInit(): void {
    this.returnData = this.pageRouteDataService.getReturnData();

    if (this.returnData) {
      this.options = this.returnData;
    }
  }

  downloadStatement() {
    if (this.selectedType === STATEMENT_TYPE.TOTAL) {
      this.generateTotalStatement();
    } else if (this.selectedType === STATEMENT_TYPE.OUT) {
      this.generateOutstandingStatement();
    }
  }

  downloadPDFStatement() {
    this.pageRouteService.navigate(
      `account-search/create-statement/${this.options?.clientid}`,
      this.returnData,
      [`account-search/guest-statement/${this.options?.clientid}`],
      {
        ...this.options,
        type: this.selectedType,
        clientid: this.options?.clientid,
        template: "gueststatement"
      }
    );
  }

  generateTotalStatement() {
    this.accountService.openTotalStatement(this.options?.clientid);
  }

  generateOutstandingStatement() {
    this.accountService.openStatement(this.options?.clientid);
  }

  back(returnData?) {
    this.pageRouteService.back(returnData);
  }
}
