<div class="px-3 pb-3 w-500 w-sm-auto" *ngIf="payment">
  <div class="d-flex flex-column color-neutral p-3 rounded-3">
    <small><strong>Payment ID:</strong> {{payment.paymentid}}</small>
    <small><strong>Date:</strong> {{payment.paymentdate | date:'dd/MM/yyyy'}}</small>
    <small><strong>Reference:</strong> {{payment.reference}}</small>
  </div>

  <div class="d-flex flex-column flex-md-row pt-1 fs-14">
    <div class="btn-action m-1 d-flex justify-content-center text-center align-items-center p-2"
      (click)="editPaymentReference()">
      <label>Edit payment reference</label>
    </div>
    <div class="btn-action m-1 d-flex justify-content-center text-center align-items-center p-2"
      (click)="unallocateItem()">
      <label>Move to unallocated account</label>
    </div>
    <div class="btn-action m-1 d-flex justify-content-center text-center align-items-center p-2" (click)="moveItem()"
      *ngIf="clientAccounts.length > 1">
      <label>Move to a different account with the same client</label>
    </div>
  </div>
</div>