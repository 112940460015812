import {Component, Input, OnInit} from '@angular/core';
import {SimpleModalCloseOptions, SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';
import {CreateStatementComponent} from 'src/app/pages/accounts/create-statement/create-statement.component';

@Component({
  selector: 'app-transaction-statement',
  templateUrl: './transaction-statement.component.html',
  styleUrls: ['./transaction-statement.component.scss']
})
export class TransactionStatementComponent extends CreateStatementComponent {

  @Input() title: string = "Transaction Statement";

  @Input() buttons = [
    {
      text: 'downloadExcel',
      class: 'btn__primary p-2',
      leadingIcon: 'download',
      wrappingClass: 'w-50 m-0 p-1' ,
      callback: () => {
        super.downloadStatement();
      }
    },
    {
      text: 'viewStatement',
      class: 'btn__primary p-2',
      wrappingClass: 'w-50 m-0 p-1',
      callback: () => {
        super.downloadPDFStatement();
      }
    },
  ];

  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'back',
    preventAutoClose: true,
  };

  updateSelectedType(value) {
    this.selectedType = value;
  }
}