<div class="d-flex flex-column flex-sm-row gap-2">
    <div class="flex-sm-3">
        <div class="fs-16 fw-semi-bold mb-3">
            Details
        </div>

        <div class="d-flex flex-column flex-sm-row justify-content-sm-between mb-sm-3">
            <div class="d-flex flex-column gap-sm-2">
                <div class="d-flex gap-1">
                    <div class="fs-14 flex-1 text-nowrap"> {{ "accounts" | translate: "accountID" }}:</div>
                    <div class="fs-14 fw-semi-bold flex-1"> {{ account.bbaccountid }}</div>
                </div>

                <div class="d-flex gap-1">
                    <div class="fs-14 flex-1"> {{ "accounts" | translate: "status" }}:</div>
                    <div class="fs-14 fw-semi-bold flex-1"> {{ account.statusdisplay }}</div>
                </div>
            </div>

            <div>
                <cui-button
                        *ngIf="account.status == 'C' && items.length"
                        (click)="updateAccountStatus.emit()"
                        mode="primary"
                        classes="my-3 my-sm-0 p-2 w-100 max-h-sm-48"
                >
                    <span class="fs-14"> {{ "accountSummary" | translate:'accountOpen' }} </span>
                </cui-button>

                <cui-button
                        *ngIf="account.status == 'O'"
                        (click)="updateAccountStatus.emit()"
                        mode="primary"
                        classes="my-3 my-sm-0 p-2 w-100 max-h-sm-48"
                >
                    <span class="fs-14"> {{ "accountSummary" | translate:'accountClose' }} </span>
                </cui-button>
            </div>
        </div>


        <div class="d-flex flex-row gap-2 mb-3">
            <div class="d-flex w-100 flex-1">
                <cui-input type="text" classes="h-48" [readonly]="true" [sizing]="'md'" label="Invoice To" class="w-100" [internalValue]="client.fullname"></cui-input>
            </div>
            <div class="d-flex flex-row my-1 mb-sm-0">
                <button class="btn__icon btn__regular-icon--active-green border-radius-50 ms-1 btn-desktop h-40 w-40"
                        (click)="editClient.emit()"
                        ngbTooltip="{{'bookingSummary' | translate:'clientEdit'}}" tooltipClass="tooltip__container">
                    <div class="d-flex align-items-center justify-content-center">
                        <svg class="ic ic_person">
                            <use href="assets/images/sprite.svg#person"/>
                        </svg>
                    </div>
                </button>

                <button class="btn__icon btn__regular-icon--active-blue border-radius-50 ms-1 btn-desktop h-40 w-40"
                        (click)="changeClient.emit()" *ngIf="account.status !== 'C'"
                        ngbTooltip="Change Client" tooltipClass="tooltip__container">
                    <div class="d-flex align-items-center justify-content-center">
                        <svg class="ic ic_add_person">
                            <use href="assets/images/sprite.svg#person-add"/>
                        </svg>
                    </div>
                </button>
            </div>
        </div>

        <div class="d-flex w-100 mb-3">
            <cui-input
                    type="text"
                    classes="h-48"
                    [sizing]="'md'"
                    label="Reference"
                    placeholder="Enter Reference here"
                    class="w-100"
                    [maxLength]="30"
                    [(ngModel)]="account.reference"
            >
            </cui-input>
        </div>

        <div class="d-flex flex-column flex-sm-row gap-2">
            <div class="d-flex mb-3 gap-2 lh-sm-45 max-w-50 max-sm-w-100 justify-content-between justify-content-sm-start flex-sm-2">
                <div class="fs-14 flex-1 text-nowrap"> {{ "accountSummary" | translate: "proForma" }}:</div>
                <div class="fs-14 fw-semi-bold flex-1 text-nowrap text-align-right text-sm-start"
                     *ngIf="account.proformano"> {{ account.proformano }}
                </div>
                <div class="fs-14 fw-semi-bold flex-1 text-nowrap text-align-right text-sm-start"
                     *ngIf="!account.proformano"> -
                </div>
            </div>
            <div class="d-flex gap-2 mb-3 flex-sm-2" *ngIf="pdfViewerActive">

                <div class="flex-1"
                     *ngIf="invoiceTemplatePreference === constants.PDF || invoiceTemplatePreference === constants.NOSELECTION">
                    <button class="btn btn-primary btn__primary_navy_light  ms-1 w-100 min-w-130 h-48 text-nowrap"
                            (click)="createProForma.emit()">
                        <div class="d-flex align-items-center justify-content-center">
                            <span class="color-secondary-text fw-semibold">{{ "accountSummary" | translate: "viewInvoice" }}</span>
                        </div>
                    </button>
                </div>

                <div class="flex-1" *ngIf="invoiceTemplatePreference != constants.PDF">
                    <div class="position-relative">
                        <button class="btn btn-primary btn__primary_navy_light ms-1 w-100 min-w-130 h-48 text-nowrap"
                                (click)="downloadProForma.emit()"
                                [hidden]="invoiceTemplatePreference === constants.PDF">
                            <div class="d-flex align-items-center justify-content-center">
                                <svg class="ic ic_file_excel me-1 h-25 w-24">
                                    <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
                                </svg>
                                <span class="color-secondary-text fw-semibold">{{ "accountSummary" | translate: "generate" }}
                </span>
                            </div>
                        </button>
                        <div class="tooltip-excel-settings">
                            <app-tool-tip class="m-left-8" *media="breakpoints.DESKTOP"
                                          [hidden]="invoiceTemplatePreference !== constants.NOSELECTION || !pdfViewerActive"
                                          type="accounting-documents"></app-tool-tip>
                        </div>
                    </div>
                </div>

                <div *ngIf="!(invoiceTemplatePreference === constants.PDF || invoiceTemplatePreference === constants.NOSELECTION)"
                     class="flex-1">
                    <!-- PLACEHOLDER DIV: DO NOT DELETE -->
                </div>
            </div>

            <div class="d-flex gap-2 mb-3 flex-sm-2" *ngIf="!pdfViewerActive">
                <div class="flex-1">
                    <button class="btn btn-primary btn__primary_navy_light ms-1 w-100 min-w-130 h-48 text-nowrap"
                            (click)="downloadProForma.emit()">
                        <div class="d-flex align-items-center justify-content-center">
                            <svg class="ic ic_file_excel me-1 h-25 w-24">
                                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
                            </svg>
                            <span class="color-secondary-text fw-semibold">{{ "accountSummary" | translate: "generate" }}
            </span>
                        </div>
                    </button>
                </div>

                <div class="flex-1">
                    <!-- PLACEHOLDER DIV: DO NOT DELETE -->
                </div>
            </div>
        </div>

        <div class="horizontal-rule mb-3"></div>

        <div class="fs-16 fw-semi-bold mb-3">
            {{ "accountSummary" | translate:'finalInvoice' }}
        </div>

        <div class="d-flex flex-column flex-sm-row gap-2">
            <div class="d-flex flex-1 mb-3 mb-sm-0 gap-2 lh-sm-45 max-w-50 max-sm-w-100  justify-content-sm-start flex-sm-2">
                <div class="fs-14 flex-1 text-nowrap"> Inv Nr:</div>
                <div class="fs-14 fw-semi-bold flex-1 text-nowrap text-align-right text-sm-start"
                     *ngIf="account.invoiceno"> {{ account.invoiceno | invoicedNo }}
                </div>
                <div class="fs-14 fw-semi-bold flex-1 text-nowrap text-align-right text-sm-start"
                     *ngIf="!account.invoiceno"> -
                </div>
            </div>
            <div class="d-flex flex-1 gap-2 mb-3 mb-sm-0 flex-sm-2" *ngIf="pdfViewerActive">
                <div class="flex-1"
                     *ngIf="items.length !== 0 && (invoiceTemplatePreference === constants.PDF || invoiceTemplatePreference === constants.NOSELECTION)">
                    <button class="btn btn-primary btn__primary_navy_light ms-1 w-100 min-w-130 h-48 text-nowrap"
                            (click)="createInvoice.emit(true)">
                        <div class="d-flex align-items-center justify-content-center">
                            <span class="color-secondary-text fw-semibold">{{ "accountSummary" | translate: "finalInvoice" }}</span>
                        </div>
                    </button>
                </div>


                <div class="flex-1"
                     *ngIf="!((items.length === 0 || account.invoiceno) || invoiceTemplatePreference === constants.PDF)">
                    <button class="btn btn-primary btn__primary_navy_light ms-1 w-100 min-w-130 h-48 text-nowrap"
                            (click)="createInvoice.emit(false)">
                        <div class="d-flex align-items-center justify-content-center">
                            <svg class="ic ic_file_excel me-1 h-25 w-24">
                                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
                            </svg>
                            <span class="color-secondary-text fw-semibold">{{ "accountSummary" | translate: "generate" }}</span>
                        </div>
                    </button>
                </div>

                <div class="flex-1" *ngIf="!((!account.invoiceno || invoiceTemplatePreference === constants.PDF))">
                    <button class="btn btn-primary btn__primary_navy_light ms-1 w-100 min-w-130 h-48"
                            (click)="invoiceReprintWarning.emit(false)">
                        <div class="d-flex align-items-center justify-content-center">
                            <svg class="ic ic_file_excel me-1 h-25 w-24">
                                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
                            </svg>
                            <span class="color-secondary-text fw-semibold">{{ "accountSummary" | translate: "print" }}</span>
                        </div>
                    </button>
                </div>

                <div *ngIf="!(items.length !== 0 && (invoiceTemplatePreference === constants.PDF || invoiceTemplatePreference === constants.NOSELECTION))">
                    <!-- PLACEHOLDER DIV: DO NOT DELETE -->
                </div>

            </div>

            <div class="d-flex gap-2 mb-3 mb-sm-0 flex-sm-2" *ngIf="!pdfViewerActive">
                <div class="flex-1" *ngIf="items.length !== 0 && !account.invoiceno">
                    <button class="btn btn-primary btn__primary_navy_light ms-1 w-100 min-w-130 h-48"
                            (click)="createInvoice.emit(false)">
                        <div class="d-flex align-items-center justify-content-center">
                            <svg class="ic ic_file_excel me-1 h-25 w-24">
                                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
                            </svg>
                            <span class="color-secondary-text fw-semibold">{{ "accountSummary" | translate: "generate" }}</span>
                        </div>
                    </button>
                </div>

                <div class="flex-1" *ngIf="account.invoiceno">
                    <button class="btn btn-primary btn__primary_navy_light ms-1 w-100 min-w-130 h-48"
                            (click)="invoiceReprintWarning.emit(false)">
                        <div class="d-flex align-items-center justify-content-center">
                            <svg class="ic ic_file_excel me-1 h-25 w-24">
                                <use href="assets/images/icons/sprite.svg#ic_file_excel"/>
                            </svg>
                            <span class="color-secondary-text fw-semibold">{{ "accountSummary" | translate: "print" }}</span>
                        </div>
                    </button>
                </div>

                <div class="flex-1">
                    <!-- PLACEHOLDER DIV: DO NOT DELETE -->
                </div>
            </div>
        </div>

    </div>

    <div class="d-block d-sm-none horizontal-rule mb-3"></div>

    <div class="vertical-rule"></div>

    <div class="d-flex flex-sm-2 flex-column justify-content-sm-between">
        <div>
            <div class="fs-16 fw-semi-bold mb-3">
                {{ "accountSummary" | translate: "totals" }}
            </div>

            <div class="d-flex">
                <div class="fs-14 flex-1"> {{ "accountSummary" | translate: "accommodation" }}:</div>
                <div class="fs-14 fw-semi-bold flex-1 text-sm-end"> {{ account.accomtotal | currency }}</div>
            </div>

            <div class="d-flex">
                <div class="fs-14 flex-1"> {{ "accountSummary" | translate: "extras" }}:</div>
                <div class="fs-14 fw-semi-bold flex-1 text-sm-end"> {{ account.extratotal | currency }}</div>
            </div>

            <div class="d-flex mb-3">
                <div class="fs-14 flex-1"> {{ "accountSummary" | translate: "payment" }}:</div>
                <div class="fs-14 fw-semi-bold flex-1 text-sm-end"> {{ account.paymenttotal | currency }}</div>
            </div>
        </div>


        <div>
            <div class="d-flex mb-3">
                <div class="fs-14 flex-1"> {{ "accountSummary" | translate: "outstanding" }}:</div>
                <div class="fs-14 fw-semi-bold flex-1 text-sm-end"> {{ account.outstanding | currency }}</div>
            </div>

            <div class="d-flex mb-3 mb-sm-0">

                <div class="flex-1">
                    <button class="btn btn__primary_navy_light w-100 h-48 m-0" (click)="openStatementModal.emit()">
                        <div class="d-flex align-items-center justify-content-center">
                            <span class="color-secondary-text fw-semibold">{{ "accountSummary"| translate: "statement" }}</span>
                        </div>
                    </button>
                </div>

            </div>
        </div>

    </div>


    <div class="d-block d-sm-none horizontal-rule"></div>
</div>

<div class="d-none d-sm-block horizontal-rule mb-3 mt-1"></div>
