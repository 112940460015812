import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {CuiModule} from '@cui/cui.module';
import {CUIInputComponent} from '@nightsbridge/common-ui/input';

import {ServiceModule} from 'src/app/service/service.module';
import {SharedModule} from 'src/app/shared.module';
import {ComponentsModule} from 'src/app/shared/components.module';
import {DirectivesModule} from 'src/app/shared/directives/directives.module';

import {BookingSummaryComponent} from 'src/app/pages/booking-summary/booking-summary.component';
import {CheckoutPaymentComponent} from 'src/app/pages/booking-summary/checkout-payment/checkout-payment.component';
import {ExtraComponent} from 'src/app/pages/booking-summary/extra/extra.component';
import {ExtrasComponent} from 'src/app/pages/booking-summary/extras/extras.component';
import {GuestCommunicationsComponent} from 'src/app/pages/booking-summary/guest-communications/guest-communications.component';
import {RatesComponent} from 'src/app/pages/booking-summary/rates/rates.component';
import {RoomAddComponent} from 'src/app/pages/booking-summary/room-add/room-add.component';
import {RoomChangeComponent} from 'src/app/pages/booking-summary/room-change/room-change.component';
import {SourcesComponent} from 'src/app/pages/booking-summary/sources/sources.component';
import {ClientAddComponent} from 'src/app/pages/clients/client-add/client-add.component';
import {ClientHistoryComponent} from 'src/app/pages/clients/client-history/client-history.component';
import {ClientRetireComponent} from 'src/app/pages/clients/client-retire/client-retire.component';
import {ClientSearchComponent} from 'src/app/pages/clients/client-search/client-search.component';
import {ClientTransactionAddComponent} from 'src/app/pages/clients/client-transaction-add/client-transaction-add.component';
import {CompanySearchComponent} from 'src/app/pages/clients/company-search/company-search.component';
import {TransactionsComponent} from 'src/app/pages/clients/transactions/transactions.component';
import {LoginComponent} from 'src/app/pages/login/login.component';
import {MainPageComponent} from 'src/app/pages/main-page/main-page.component';
import {ReportsComponent} from 'src/app/pages/reports/reports.component';
import {SearchComponent} from 'src/app/pages/search/search.component';
import {CancellationComponent} from 'src/app/pages/tools/cancellation/cancellation.component';
import {DoubleBookingsComponent} from 'src/app/pages/tools/double-bookings/double-bookings.component';
import {OccupancyComponent} from 'src/app/pages/tools/occupancy/occupancy.component';
import {PreferencesComponent} from 'src/app/pages/tools/preferences/preferences.component';
import {SourceReplaceComponent} from 'src/app/pages/tools/source-replace/source-replace.component';
import {ComponentModule} from '../components/component.module';
import {AccountingdocumentsComponent, ProFormaEmailModalComponent} from './accounts/accounting-documents/accountingdocuments.component';
import {AccountsComponent} from './accounts/accounts.component';
import {AccountsCloseComponent} from './accounts/accountsclose/accountsclose.component';
import {AccountsDetailComponent} from './accounts/accountsdetail/accountsdetail.component';
import {ClientAccountListComponent} from './accounts/client-account-list/client-account-list.component';
import {ClientAccountComponent} from './accounts/client-account/client-account.component';
import {PaymentComponent} from './accounts/payment/payment.component';
import {AccountInfoComponent} from './booking-details/account-info/account-info.component';
import {BookingDetailsComponent} from './booking-details/booking-details.component';
import {BookingNotesComponent} from './booking-details/booking-notes/booking-notes.component';
import {EditClientComponent} from './booking-details/widgets/edit-client/edit-client.component';
import {MultiRoomCommunicationComponent} from './booking-summary/guest-communications/multi-room-communication/multi-room-communication.component';
import {NoShowConfirmationComponent} from './booking-summary/noshow-confirmation/noshow-confirmation.component';
import {ReportBookingComponent} from './booking-summary/report-booking/report-booking.component';
import {BookingAddComponent} from './calendar/booking-add/booking-add.component';
import {CheckInOutComponent} from './calendar/check-in-out/check-in-out.component';
import {CreateEventComponent} from './calendar/create-event/create-event.component';
import {ListEventsComponent} from './calendar/list-events/list-events.component';
import {MyPropertiesComponent} from './calendar/my-properties/my-properties.component';
import {QuoteComponent} from './calendar/quote/quote.component';
import {SwapRoomsComponent} from './calendar/swap-rooms/swap-rooms.component';
import {ChangeUserComponent} from './change-user/change-user.component';
import {CreateStatementComponent} from "./accounts/create-statement/create-statement.component";
import {TapNpayModule} from "@shared/tap-n-pay/tap-n-pay.module";
import { MovePaymentComponent } from './accounts/move-payment/move-payment.component';
import { PaymentActionsComponent } from './accounts/payment-actions/payment-actions.component';
import { GuestStatementComponent } from './accounts/guest-statement/guest-statement.component';

@NgModule({
  declarations: [
    AccountsComponent,
    AccountsDetailComponent,
    AccountsCloseComponent,
    LoginComponent,
    MainPageComponent,
    ReportsComponent,
    CancellationComponent,
    DoubleBookingsComponent,
    SourceReplaceComponent,
    PreferencesComponent,
    OccupancyComponent,
    SearchComponent,
    BookingSummaryComponent,
    RoomChangeComponent,
    RoomAddComponent,
    RatesComponent,
    ExtrasComponent,
    ExtraComponent,
    GuestCommunicationsComponent,
    CheckoutPaymentComponent,
    SourcesComponent,
    ClientSearchComponent,
    ClientAddComponent,
    CompanySearchComponent,
    ClientRetireComponent,
    ClientHistoryComponent,
    TransactionsComponent,
    ClientTransactionAddComponent,
    PaymentComponent,
    SwapRoomsComponent,
    QuoteComponent,
    ListEventsComponent,
    CreateEventComponent,
    CheckInOutComponent,
    BookingAddComponent,
    ClientAccountComponent,
    ClientAccountListComponent,
    ChangeUserComponent,
    MyPropertiesComponent,
    MultiRoomCommunicationComponent,
    NoShowConfirmationComponent,
    ReportBookingComponent,
    AccountingdocumentsComponent,
    ProFormaEmailModalComponent,
    CreateStatementComponent,
    CreateStatementComponent,
    BookingDetailsComponent,
    AccountInfoComponent,
    BookingNotesComponent,
    EditClientComponent,
    MovePaymentComponent,
    PaymentActionsComponent,
    GuestStatementComponent
  ],
  imports: [
    ComponentModule,
    CommonModule,
    SharedModule,
    ComponentsModule,
    ServiceModule,
    DirectivesModule,
    CuiModule,
    CUIInputComponent,
    TapNpayModule
  ],
  entryComponents: [
    ReportsComponent,
  ]
})
export class PagesModule { }
