import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment/moment';

import {PageRouteService} from 'src/app/service/page-route.service';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {ClientsService} from 'src/app/service/clients.service';
import {Constants} from 'src/app/service/models/enum/constants.enum';
import {TransactionTypes} from 'src/app/service/models/enum/transaction-types.enum';
import {PaymentTypes} from 'src/app/service/models/enum/payment-types.enum';
import {TranslateService} from 'src/app/service/translate.service';
import {CredentialService} from "../../../service/credential.service";
import {NbLibraryService} from "../../../service/nb-library.service";
import {Transaction} from "../../../service/models/Transaction";

@Component({
  selector: 'app-client-transaction-add',
  templateUrl: './client-transaction-add.component.html',
  styleUrls: ['./client-transaction-add.component.scss']
})
export class ClientTransactionAddComponent implements OnInit {
  @Input() options;
  @ViewChild("amountInput") amountInput;

  trantypes = [];
  saveClicked = false;
  clienttran: Transaction;
  paymenttypes = [];
  trantype;

  clientTranForm = new FormGroup({
    trantype: new FormControl(undefined, Validators.required), // clienttran.trantype
    trandate: new FormControl(undefined), // clienttran.trandate
    amount: new FormControl(undefined, [ Validators.minLength(0)]), // clienttran.amount
    reference: new FormControl(undefined), // clienttran.reference
    paymenttype: new FormControl(undefined), // clienttran.paymenttype
    vat: new FormControl(undefined), // clienttran.vat
  });

  debounceOptions = {
    timeout: null,
    delay: 500, // in milliseconds
  };

  constructor(
    private pageRouteService: PageRouteService,
    private datelibraryService: DatelibraryService,
    private clientsService: ClientsService,
    private translateService: TranslateService,
    private credentialService: CredentialService,
    private nbLib: NbLibraryService
  ) {
    this.clienttran = new Transaction(this.datelibraryService);
  }

  ngOnInit(): void {
    if (!this.options) {
      this.close();
    }

    Object.values(TransactionTypes).map((value) => {
      if (
        value.valueOf() === Constants.PAYMENT.valueOf() ||
        value.valueOf() === Constants.CREDIT_NOTE.valueOf() ||
        value.valueOf() === Constants.JOURNAL.valueOf()
      ) {
        this.trantypes.push({
          trantype: value,
          description: this.translateService.translate('transactionTypes', value),
        });
      }
    });

    this.paymenttypes = Object.values(PaymentTypes)
      .filter(value => typeof (value) === 'number')
      .map((value) => {
        return {
          paymenttype: value,
          paymenttypetext: this.translateService.translate('paymentTypes', String(value)),
        };
      });

    this.clienttran.clientid =  this.options.clientid,
    this.clienttran.trandate =  this.datelibraryService.getDateObject(this.datelibraryService.getDate()),
    this.clienttran.trantype =  Constants.PAYMENT,
    this.clienttran.paymenttype =  0;

    this.setFormValues();

    this.clientTranForm.valueChanges.subscribe(() => {
      this.debounce(this.getFormValues)();
    });
  }

  close(returnData?) {
    this.pageRouteService.back(returnData);
  }

  addTransaction() {
    if (!this.saveClicked) {
      this.saveClicked = true;
      let amount = this.clienttran.amount;
      this.clienttran.amount = +amount
      if (this.clientTranForm.valid) {
        this.clientsService.addTransaction(this.clienttran).subscribe((response: any) => {
          this.close(response.data.clienttran);
        });
      } else {
        this.saveClicked = false;
      }
    }
  }

  getFormValues() {
    this.clienttran.trantype = this.clientTranForm.value.trantype;
    this.clienttran.trandate = moment(this.clientTranForm.value.trandate).format('YYYY-MM-DD');
    let amount =  this.clientTranForm.value.amount;
    this.clienttran.amount = typeof amount === 'string' ? this.nbLib.getNumberFromCurrency(amount) : amount;
    this.clienttran.reference = this.clientTranForm.value.reference;
    this.clienttran.paymenttype = this.clientTranForm.value.paymenttype;
    this.clienttran.vat = this.clientTranForm.value.vat;
  }

  setFormValues() {
    this.clientTranForm.patchValue({
      trantype: this.clienttran.trantype,
      trandate: this.clienttran.trandate,
      amount: this.clienttran.amount ? this.clienttran.amount : null,
      reference: this.clienttran.reference ? this.clienttran.reference : null,
      paymenttype: this.clienttran.paymenttype,
      vat: this.clienttran.vat,
    });
  }

  showVat() {
    const isVatRegistered = this.credentialService.getCurrentUser.value.isVatRegistered;
    return isVatRegistered && (
      this.clienttran.trantype === Constants.JOURNAL ||
      this.clienttran.trantype === Constants.CREDIT_NOTE
    );
  }

  updateVat() {
    // TODO get from cache
    // let vatCoefficient = nb.cache.vatrate / (1 + nb.cache.vatrate); // (e.g. 0.15 / 1.15)
    this.formatAmount();
    const vatCoefficient = this.credentialService.getCurrentUser.value.vatRate / (1 + this.credentialService.getCurrentUser.value.vatRate);
    this.clienttran.vat = this.isVatableTransaction(this.clienttran.trantype)
      ? Math.round(this.clienttran.amount * vatCoefficient * 100) / 100
      : 0;

    this.clientTranForm.get('vat').patchValue(this.clienttran.vat);
  }

  isVatableTransaction(tranType) {
    // let isVatRegistered = nb.cache.isVatRegistered // TODO get from cache
    const isVatRegistered = true; // TODO remove after correctly getting it from cache
    return isVatRegistered && (
      tranType === Constants.JOURNAL ||
      tranType === Constants.CREDIT_NOTE
    );
  }

  formatAmount(){
    let amount = this.nbLib.getNumberFromCurrency(this.clientTranForm.value.amount);
    this.clientTranForm.patchValue({'amount': this.nbLib.formatCurrency(amount)});
  }

  debounce(func) {
    return () => {
      let later = () => {
        this.debounceOptions.timeout = null;
        func.apply(this);
      };
      clearTimeout(this.debounceOptions.timeout);
      this.debounceOptions.timeout = setTimeout(later, this.debounceOptions.delay);
    };
  }
}
