import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {SharedModule} from 'src/app/shared.module';
import {ServiceModule} from 'src/app/service/service.module';
import {DirectivesModule} from 'src/app/shared/directives/directives.module';

import {LoadingscreenComponent} from 'src/app/shared/loadingscreen/loadingscreen.component';
import {MenuComponent} from 'src/app/shared/menu/menu.component';
import {ConfirmationDialogComponent} from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import {DateRangeComponent} from 'src/app/shared/date-range/date-range.component';
import {PromptDialogComponent} from 'src/app/shared/prompt-dialog/prompt-dialog.component';
import {TimeRangeComponent} from './time-range/time-range.component';
import {NetworkAlertComponent} from './network-alert/network-alert.component';
import {ToolTipComponent} from './tool-tip/tool-tip.component';
import {MediaComponent} from "./media/media.component";
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import {PanelContainerComponent} from './panel/panel-container/panel-container.component';
import {SimpleTabsComponent} from './simple-tabs/simple-tabs.component';
import {SimpleTabDirective} from './simple-tabs/simple-tab.directive';
import {BookingDateRangeComponent} from './booking-date-range/booking-date-range.component';

import {CuiModule} from '@cui/cui.module';
import {CUIInputComponent} from '@nightsbridge/common-ui/input';

import {SimpleModalComponent} from './simple-modal/simple-modal.component';
import { PromptModalComponent } from './prompt-modal/prompt-modal.component';
import {TapNpayModule} from "@shared/tap-n-pay/tap-n-pay.module";

/**
 * Feature module with all custom and reusable components for the project.
 */
@NgModule({
  declarations: [
    LoadingscreenComponent,
    MenuComponent,
    DateRangeComponent,
    ConfirmationDialogComponent,
    PromptDialogComponent,
    TimeRangeComponent,
    NetworkAlertComponent,
    ToolTipComponent,
    MediaComponent,
    ConfirmationModalComponent,
    PanelContainerComponent,
    SimpleTabsComponent,
    SimpleTabDirective,
    BookingDateRangeComponent,
    SimpleModalComponent,
    PromptModalComponent
  ],
  exports: [
    LoadingscreenComponent,
    MenuComponent,
    DateRangeComponent,
    ConfirmationDialogComponent,
    PromptDialogComponent,
    TimeRangeComponent,
    ToolTipComponent,
    MediaComponent,
    PanelContainerComponent,
    SimpleTabsComponent,
    SimpleTabDirective,
    BookingDateRangeComponent,
    SimpleModalComponent,
    TapNpayModule,
    SharedModule,
  ],
  imports: [
    SharedModule,
    ServiceModule,
    DirectivesModule,
    
    CuiModule,
    CUIInputComponent,

    TapNpayModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class ComponentsModule {
}
