import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GuestStatementPreviewComponent} from 'src/app/components/account-info/widgets/account-summary/guest-statement-preview/guest-statement-preview.component';
import {AccountService} from 'src/app/service/account.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {CredentialService} from 'src/app/service/credential.service';
import {DisplayManagerService} from 'src/app/service/display-manager.service';
import {EmailService} from 'src/app/service/email.service';
import {PageRouteService} from 'src/app/service/page-route.service';

@Component({
  selector: 'app-guest-statement',
  templateUrl: './guest-statement.component.html',
  styleUrls: ['./guest-statement.component.scss']
})
export class GuestStatementComponent extends GuestStatementPreviewComponent {

  constructor(authService: CredentialService, accountService: AccountService, confirmationService: ConfirmationService, pageRouteService: PageRouteService, http: HttpClient, emailService: EmailService, credentialService: CredentialService, modalService: NgbModal) {
    super(authService, accountService, confirmationService, pageRouteService, http, emailService, credentialService, modalService);
  }

}
