<div class="d-flex flex-column flex-sm-row gap-4 align-items-sm-center m-3" [formGroup]="roomAddForm">
  <section class="d-flex flex-column flex-2 gap-3 gap-sm-3">

    <cui-input type="select" class="w-100 status-input" [dropdownOptions]="availableRooms"
      dropdownOptionsLabel="roomname" dropdownOptionsValue="bbroomid" label="Available Rooms"
      [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'" formControlName="selectedRoomId"
      (change)="updateMealPlans()">
    </cui-input>

    <div class="d-flex flex-row align-items-center">
      <cui-input label="Client" placeholder="Enter Client" classes="h-48" class="flex-1" formControlName="fullname"
        [sizing]="'md'">
      </cui-input>

      <button class="btn__icon btn__icon--round-always btn__regular-icon--active-blue ms-1 w-100 h-48 icon-backround"
        (click)="setClient()" ngbTooltip="Change Client" tooltipClass="tooltip__container">
        <div class="d-flex align-items-center justify-content-center">
          <svg class="ic ic_person">
            <use href="assets/images/sprite.svg#person" />
          </svg>
        </div>
      </button>
    </div>
  </section>

  <section class="d-flex flex-column flex-3 gap-3 gap-sm-3">
    <cui-input type="select" class="w-100 status-input" [dropdownOptions]="roomrates" dropdownOptionsLabel="description"
      dropdownOptionsValue="bbrateid" label="Applied Rate"
      [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'" formControlName="bbrateid">
    </cui-input>

    <div class="d-flex flex-column flex-sm-row gap-2">
      <div class="d-flex w-100">
        <cui-input name="radio" label="Adults" formControlName="noadults" for="fullReversal" classes="fs-14"
          [sizing]="'md'" class="flex-grow-1 ml-2 me-1"></cui-input>
        <cui-input name="radio" label="Child(0-11)" formControlName="child1" for="fullReversal" classes="fs-14"
          [sizing]="'md'" class="flex-grow-1 ml-2 me-1"></cui-input>
        <cui-input name="radio" label="Child(12-12)" formControlName="child2" for="fullReversal" classes="fs-14"
          [sizing]="'md'" class="flex-grow-1 ml-2 me-1"></cui-input>
      </div>
    </div>

  </section>
</div>