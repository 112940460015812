import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tap-n-pay-modal-confirm',
  templateUrl: './tap-n-pay-modal-confirm.component.html',
  styleUrls: ['./tap-n-pay-modal-confirm.component.scss']
})
export class TapNPayModalConfirmComponent {

  constructor(public activeModal: NgbActiveModal) {}

  accept(){
    this.activeModal.close(true);
  }

  reject() {
    this.activeModal.close(false);
  }
}
