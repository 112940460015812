<ng-container *ngIf="(bookingSummary$ | async) as bookingSummary">
    <div [formGroup]="bookingInfoForm" class="mt-3 room-shadow border-radius-16 p-2 pt-3">

        <div class="d-flex justify-content-between align-items-center mb-3">
            <span class="fw-semi-bold">
                {{ (bookingSummary.guests && (bookingSummary.selectedguestIndex !== undefined) && bookingSummary.guests[bookingSummary.selectedguestIndex]) ? bookingSummary.guests[bookingSummary.selectedguestIndex].roomname : ''}}
             </span>

             <div class="d-flex align-items-center" *ngIf="bookingSummary.guests?.length > 1">
                <div class="subsection-heading me-2">
                    <span>Room</span>
                    {{bookingSummary.selectedguestIndex + 1}}/{{bookingSummary.guests?.length}}
                </div>

                <div ngbDropdown class="d-inline-block" placement="bottom-right">
                <div class="icon"
                     ngbDropdownToggle
                     [class.rotate]="isIconRotated"
                     (click)="toggleIconRotation()"
                     >
                    </div>
                <div ngbDropdownMenu class="roomDropdown pt-0">
                  <div class="p-1">
                    <cui-input type="text" [placeholder]="'bookingSummary' | translate : 'search'" [(ngModel)]="roomFilter"
                    [ngModelOptions]="{standalone: true}"></cui-input>
                  </div>
                  <button
                    ngbDropdownItem
                    *ngFor="let guest of bookingSummary.guests | filter : roomFilter : 'roomname'"
                    (click)="changeRoomView.emit(guest)"
                    (click)="toggleIconRotation()">
                    {{guest?.roomname}}
                  </button>
                </div>
              </div>
            </div>

        </div>

        <span class="mt-2 fs-14 ml-1 fw-medium">Room options</span>

        <div class="d-flex mt-2 gap-2 flex-wrap flex-sm-nowrap mb-3">
            <cui-button classes="w-100 btn btn__roomoptions" class="flex-1 flex-basis-48 flex-basis-sm-0 h-40" (click)="addRoom.emit()"> Add </cui-button>
            <cui-button classes="w-100 btn btn__roomoptions" class="flex-1 flex-basis-48 flex-basis-sm-0 h-40" (click)="changeRoom.emit()" *ngIf="!bookingSummary.changeRoomDisabled"> Change </cui-button>
            <cui-button classes="w-100 btn btn__roomoptions" class="flex-1 flex-basis-48 flex-basis-sm-0 h-40" (click)="splitRoom.emit()" *ngIf="bookingSummary.guests?.length > 1"> Split </cui-button>
            <cui-button classes="w-100 btn btn__roomoptions" class="flex-1 flex-basis-48 flex-basis-sm-0 h-40" (click)="cancelRoom.emit()" *ngIf="bookingSummary.guests?.length > 1"> Cancel </cui-button>
        </div>

        <div class="d-flex mt-1 mb-3">
            <cui-input type="select" class="w-100" dropdownOptionsLabel="description" [dropdownOptions]="bookingSummary.guest?.arroomrates"
            dropdownOptionsValue="bbrateid" label="Rates" [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'"
            formControlName="bbrateid" (change)="toggleRates.emit()"  [trailingIconColor]="'color-secondary-text'">
            </cui-input>
        </div>

        <div class="flex-sm-1 d-block d-sm-none mb-2">
            <cui-input type="number" [sizing]="'md'" label="Adults" class="w-100" formControlName="noadults"></cui-input>
        </div>

        <div class="d-flex gap-2  h-48 mt-1 mb-3">
            <div class="d-flex flex-1 flex-sm-2 gap-2">

                <div class="flex-sm-1 d-none d-sm-block">
                    <cui-input type="number" classes="h-48" [sizing]="'md'" label="Adults" class="w-100 me-2 mb-sm-0 me-2" formControlName="noadults" (input)="onInputChange($event)"></cui-input>
                </div>

                <div class="flex-sm-1">
                    <cui-input type="number" classes="h-48" [sizing]="'md'" [label]="'Child (' + options?.childDisplay1 + ')'" class="flex-grow-1 ml-2 me-2 no-spinner" formControlName="child1" (input)="onInputChange($event)"></cui-input>
                </div>

            </div>


            <div class="flex-1">
                <cui-input *ngIf="bookingSummary.establishment.childage2 > 0" type="number" classes="h-48" [sizing]="'md'" [label]="'Child (' + options?.childDisplay2 + ')'" class="flex-grow-1 ml-2 mr-2 no-spinner" formControlName="child2" (input)="onInputChange($event)"></cui-input>
            </div>
        </div>

        <div class="d-flex flex-column flex-sm-row gap-2 mb-3">
            <div class="d-flex w-100 flex-sm-2">
                <cui-input type="text" classes="h-48" [readonly]="true" [sizing]="'md'" label="Guest" class="w-100 mb-2 mb-sm-0" [internalValue]="bookingSummary.guest?.clientfullname"></cui-input>
            </div>
            <div class="d-flex flex-sm-1 flex-row my-1 mt-sm-2 mb-sm-0">
                <button class="btn__icon btn__icon--round-desktop btn__regular-icon--active-green border-radius-50 ms-1 btn-desktop" (click)="editClient.emit(bookingSummary.guest.clientid)"
                ngbTooltip="{{'bookingSummary' | translate:'guestEdit'}}" tooltipClass="tooltip__container">
                <div class="d-flex align-items-center justify-content-center">
                    <svg class="ic ic_person">
                        <use href="assets/images/sprite.svg#person" />
                    </svg>
                </div>
            </button>

            <button class="btn__icon btn__icon--round-desktop btn__regular-icon--active-blue border-radius-50 ms-1 btn-desktop" (click)="changeGuestClient.emit()"
                ngbTooltip="Change Guest" tooltipClass="tooltip__container">
                <div class="d-flex align-items-center justify-content-center">
                    <svg class="icon-svg">
                        <use href="assets/images/sprite.svg#person-add" />
                    </svg>
                </div>
            </button>

            <button class="btn__icon btn__icon--round-desktop btn__regular-icon--active-blue border-radius-50 ms-1 btn-desktop" [disabled]="!bookingSummary.guest?.clientemail"
                ngbTooltip="Email Guest" tooltipClass="tooltip__container" >
                <a (click)="sendEmail.emit(bookingSummary.guest?.clientemail)"
                href="mailto:{{bookingSummary.guest?.clientemail}}">
                <div class="d-flex align-items-center justify-content-center">
                    <svg class="icon-svg">
                        <use href="assets/images/sprite.svg#email" />
                    </svg>
                </div>
                </a>
            </button>

            <button class="btn__icon btn__icon--round-desktop btn__regular-icon--active-blue border-radius-50 ms-1 btn-desktop" [disabled]="!guestAccountExists" (click)="viewGuestAccount.emit()"
                ngbTooltip="View Guest Account" tooltipClass="tooltip__container">
                <div class="d-flex align-items-center justify-content-center">
                    <svg class="icon-svg">
                        <use href="assets/images/sprite.svg#calculator" />
                    </svg>
                </div>
            </button>
            </div>
        </div>

        <div class="d-flex flex-column flex-sm-row gap-2 mb-3">
            <div class="d-flex w-100 flex-sm-2">
                <cui-input type="text" [readonly]="true" classes="h-48" [sizing]="'md'" label="2nd Guest" class="w-100 mb-2 mb-sm-0" [internalValue]="bookingSummary.guest?.client2fullname ? bookingSummary.guest?.client2fullname : ''"></cui-input>
            </div>
            <div class="d-flex flex-row my-1 mt-sm-2 mb-sm-0 flex-sm-1">
                <button class="btn__icon btn__icon--round-desktop btn__regular-icon--active-green border-radius-50 ms-1 btn-desktop"
                ngbTooltip="{{'bookingSummary' | translate:'guestEdit'}}" tooltipClass="tooltip__container"  [disabled]="bookingSummary.guest?.client2id === 0"  (click)="editClient2.emit(bookingSummary.guest.client2id)">
                <div class="d-flex align-items-center justify-content-center">
                    <svg class="ic ic_person">
                        <use href="assets/images/sprite.svg#person" />
                    </svg>
                </div>
                </button>

            <button class="btn__icon btn__icon--round-desktop btn__regular-icon--active-blue border-radius-50 ms-1 btn-desktop"
                ngbTooltip="Change Guest" tooltipClass="tooltip__container" (click)="changeGuest2Client.emit()">
                <div class="d-flex align-items-center justify-content-center">
                    <svg class="icon-svg">
                    <use href="assets/images/sprite.svg#person-add" />
                    </svg>
                </div>
            </button>

            <button class="btn__icon btn__icon--round-desktop btn__regular-icon--active-blue border-radius-50 ms-1 btn-desktop" [disabled]="!bookingSummary.guest?.client2email"
                ngbTooltip="Email Guest" tooltipClass="tooltip__container">
                <a (click)="sendEmail.emit(bookingSummary.guest?.client2email)" href="mailto:{{bookingSummary.guest?.client2email}}">
                    <div class="d-flex align-items-center justify-content-center">
                        <svg class="icon-svg">
                            <use href="assets/images/sprite.svg#email" />
                        </svg>
                    </div>
                </a>
            </button>

            <button class="btn__icon btn__icon--round-desktop btn__regular-icon--active-blue border-radius-50 ms-1 btn-desktop" [disabled]="bookingSummary.guest?.client2id === 0" (click)="clearGuest2Client.emit()" [disabled]="bookingSummary.guest?.client2id === 0"
                ngbTooltip="Clear Guest" tooltipClass="tooltip__container">
                <a>
                    <div class="d-flex align-items-center justify-content-center">
                        <svg class="icon-svg">
                            <use href="assets/images/sprite.svg#remove-filled" />
                        </svg>
                    </div>
                </a>
            </button>
            </div>
        </div>

        <div class="d-flex mb-2 mt-2 p-2 gap-4">
            <div class="me-2 checkbox-line-height" (click)="validateCheckIn.emit()">
                <input class="form-check-input fs-14" type="checkbox" formControlName="checkedin" />
                <label class="form-check-label fs-14">
                    {{'bookingSummary'|translate:'checkedIn'}}
                </label>
            </div>
            <div class="me-2 checkbox-line-height" (click)="validateCheckOut.emit()">
                <input class="form-check-input fs-14" type="checkbox" formControlName='checkedout' />
                <label class="form-check-label fs-14">
                    {{'bookingSummary'|translate:'out'}}
                </label>
            </div>
        </div>

        <div class="d-flex flex-column flex-sm-row mt-2">
            <div class="d-flex w-100">
                <cui-button mode="primary" classes="w-100 p-2" class="flex-shrink-1 m-2 h-40" (click)="guestCommunications.emit()"> Guest Communication </cui-button>
            </div>
            <div class="d-flex w-100">
                <cui-button mode="primary" classes="w-100 p-2" *ngIf="!bookingSummary.changeRoomDisabled" class="flex-shrink-1 m-2 h-40" (click)="viewExtras.emit()"> View Extras </cui-button>

                <div *ngIf="guestAccountExists" class="btn__badge_responsive">
                    <sup class="btn__badge_responsive--icon">
                        <svg class="ic ic_bell absoluteCenter">
                            <use href="assets/images/icons/sprite.svg#ic_bell" />
                        </svg>
                    </sup>
                </div>
            </div>
        </div>
    </div>
</ng-container>
