<div class="clientAccountContainer">
  <div class="modal-header d-flex justify-content-between">
    <span class="typography__h3">
      {{'clientAccount' | translate: 'clientAccountInstructions'}}
    </span>
    <div class="close-modal" (click)="close()">
      <span class="helper"></span>
      <img src="assets/images/close.svg" />
    </div>
  </div>

  <div class="modal-body">
    <div class="d-flex flex-column p-3">
      <small><strong>Payment ID:</strong> {{payment.paymentid}}</small>
      <small><strong>Date:</strong> {{payment.paymentdate | date:'dd/MM/yyyy'}}</small>
      <small><strong>Reference:</strong> {{payment.reference}}</small>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-between accountActionContainer">
    <button class="btn__regular btn btn__secondary" (click)="editPaymentReference()">
      Edit payment reference
    </button>

    <button class="btn__regular btn btn__secondary" (click)="unallocateItem()">
      Move to unallocated account
    </button>
    <button class="btn__regular btn btn__secondary"
      [ngStyle]="{visibility: clientAccounts.length > 1 ? 'visible' : 'hidden'}" (click)="moveItem()">
      Move to a different account with the same client
    </button>
  </div>
</div>