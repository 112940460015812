import {Component, Input} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TapNPayModalConfirmComponent} from "../tap-n-pay-modal-confirm/tap-n-pay-modal-confirm.component";
import {tapNpayModalOptions} from "../../tap-n-pay.class";

@Component({
  selector: 'app-tap-n-pay-modal-container',
  templateUrl: './tap-n-pay-modal-container.component.html',
  styleUrls: ['./tap-n-pay-modal-container.component.scss']
})
export class TapNpayModalContainerComponent {

  @Input() confirmBeforeClose: boolean = false;
  @Input() title = 'Tap & Pay';

  constructor(
    private activeModal: NgbActiveModal,
    private modal: NgbModal
  ) { }

  close () {
    if (this.confirmBeforeClose) {
      const confirmModal = this.modal.open(TapNPayModalConfirmComponent, tapNpayModalOptions);
      confirmModal.closed.subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.activeModal.close();
        }
      });
    } else {
      this.activeModal.close();
    }
  }
}
