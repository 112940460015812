import {DatelibraryService} from '../datelibrary.service';
import {Client} from "./Client";

export class Transaction{
    accountid?;
    trandate;
    trantype;
    tranno;
    amount;
    reference;
    startdate;
    enddate;
    tranDesc;
    client: Client;
    clientid: any;
    paymenttype: number;
    vat: any;

    constructor(private datelibraryService: DatelibraryService) {
        this.trantype = '%';
        this.setLastMonth();
    }

    setLastMonth() {
        this.startdate = this.datelibraryService.setFirstDayOfMonth(
            this.datelibraryService.getDate(this.datelibraryService.addMonths(this.datelibraryService.getDate(), -1))
        );
        this.enddate = this.datelibraryService.setLastDayOfMonth(this.startdate);
    }
}
