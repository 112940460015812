<app-tap-n-pay-modal-container [confirmBeforeClose]="confirmBeforeClose">
    <div>
        <div class="d-flex w-100 h-100 justify-content-center">
            <div class="spinner-border large-spinner text-primary" role="status"></div>
        </div>
        <div class="rfid-icon-container d-flex w-100 h-100 justify-content-center position-relative">
            <svg class="ic-rfid">
                <use href="assets/images/sprite.svg#rfid"/>
            </svg>
        </div>
        <p class="fs-20 fw-semi-bold text-center"> {{ message }} </p>

        <p *ngIf="secondsLeft > 0" class="tnp-blue fw-semi-bold text-align-center text-center">
            {{secondsToMinutes(secondsLeft)}}
        </p>
    </div>

  <div class="d-flex modal-footer border-top justify-content-between fw-semi-bold text-align-center text-center" *ngIf="secondsLeft > 0">
      The transaction will timeout after {{timeoutAfterLabel}} if not completed.
  </div>
</app-tap-n-pay-modal-container>


