<app-tap-n-pay-modal-container>
        <div class="d-flex justify-content-center my-3">
            <img src="assets/images/tap_n_pay.svg"/>
        </div>
      <ng-container *ngIf="source === TapNpaySources.BRIDGEIT">
        <p class="text-center f-18 p-2 wrap-text fw-semi-bold" *ngIf="devicesBT.length > 0">
          Select the mobile device you would like to use.
        </p>
        <p class="text-center f-18 p-2 wrap-text fw-semi-bold" *ngIf="devicesBT.length === 0">
          No devices detected.
        </p>
      </ng-container>
      <ng-container *ngIf="source === TapNpaySources.NIGHTSBRIDGE">
        <p class="text-center f-18 p-2 wrap-text fw-semi-bold" *ngIf="devicesNB.length > 0">
          Select the mobile device you would like to use.
        </p>
        <p class="text-center f-18 p-2 wrap-text fw-semi-bold" *ngIf="devicesNB.length === 0">
          No devices detected.
        </p>
      </ng-container>
        <div class="d-flex p-2 flex-wrap w-100 gap-2">
          <ng-container *ngIf="source === TapNpaySources.BRIDGEIT">
            <div
              *ngFor="let device of devicesOnPageBT; let position = index"
              (click)="selectDeviceBT(device, position)"
              class="device-button w-min-45p flex-1 cursor-pointer text-center border-radius-16"
              [ngClass]="{
             'inactive-device': device.state === 'EVT_DEVICE_STATUS_DISABLED',
             'selected-device':  (selectedDeviceIndex === position),
             'text-primary':  (selectedDeviceIndex === position)
           }"
            >
              <div class="fs-12 fw-bold"> {{device.manufacturer | titlecase}} {{device.model}} </div>
              <div class="fs-12 fw-semi-bold"> {{device.deviceId}}</div>
              <div class="fs-12 fw-semi-bold"
                   [ngClass]="{'offline': device.state !== TapNpayDeviceStatuses['DEVICE_STATUS_ENABLED'], 'online': device.state === TapNpayDeviceStatuses['DEVICE_STATUS_ENABLED']}">
                {{ device.state === TapNpayDeviceStatuses['DEVICE_STATUS_ENABLED'] ? 'Online' : 'Offline' }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="source === TapNpaySources.NIGHTSBRIDGE">
            <div
              *ngFor="let device of devicesOnPageNB; let position = index"
              (click)="selectDeviceNB(device, position)"
              class="device-button w-min-45p flex-1 cursor-pointer text-center border-radius-16"
              [ngClass]="{
             'inactive-device': device.state === 'EVT_DEVICE_STATUS_DISABLED',
             'selected-device':  (selectedDeviceIndex === position),
             'text-primary':  (selectedDeviceIndex === position)
           }"
            >
              <div class="fs-12 fw-bold"> {{device.manufacturer | titlecase}} {{device.model}} </div>
              <div class="fs-12 fw-semi-bold"> {{device.deviceid}}</div>
              <div class="fs-12 fw-semi-bold"
                   [ngClass]="{'offline': device.state !== TapNpayDeviceStatuses['DEVICE_STATUS_ENABLED'], 'online': device.state === TapNpayDeviceStatuses['DEVICE_STATUS_ENABLED']}">
                {{ device.state === TapNpayDeviceStatuses['DEVICE_STATUS_ENABLED'] ? 'Online' : 'Offline' }}
              </div>
            </div>
          </ng-container>
        </div>
        <cui-carousel-paginator *ngIf="source === TapNpaySources.BRIDGEIT" [items]="devicesBT" [maxItemsPerPage]="6" (getCurrentSection)="setDevicesOnPageBT($event)"></cui-carousel-paginator>
        <cui-carousel-paginator *ngIf="source === TapNpaySources.NIGHTSBRIDGE" [items]="devicesNB" [maxItemsPerPage]="6" (getCurrentSection)="setDevicesOnPageNB($event)"></cui-carousel-paginator>
        <cui-button mode="primary" classes="mt-2 p-1 p-sm-2 h-40 tap-n-pay-button-override"><span class="fs-14" (click)="refreshDeviceList()"> Refresh Device List </span></cui-button>
</app-tap-n-pay-modal-container>

<div class="d-flex modal-footer border-top justify-content-between">
  <cui-button class="d-flex flex-1" (onClick)="back()" label="Back" name="cancel" *ngIf="!this.isRefund" classes="tap-n-pay-btn-cancel tap-n-pay-btn-action-override" mode="light"></cui-button>
  <cui-button class="d-flex flex-1" (onClick)="close()" label="Close" name="cancel" *ngIf="this.isRefund" classes="tap-n-pay-btn-cancel tap-n-pay-btn-action-override" mode="light"></cui-button>
  <cui-button class="d-flex flex-1" (onClick)="confirm()" label="Confirm" name="confirm" [disabled]="isConfirmDisabled" *ngIf="!this.isRefund" classes="tap-n-pay-btn-success tap-n-pay-btn-action-override" mode="light"></cui-button>
  <cui-button class="d-flex flex-1" (onClick)="confirm()" label="Refund" name="refund" [disabled]="isConfirmDisabled" *ngIf="this.isRefund" classes="tap-n-pay-btn-success tap-n-pay-btn-action-override" mode="light"></cui-button>
</div>


