import {Component, Input} from '@angular/core';
import {SimpleModalCloseOptions, SimpleModalContent} from "@shared/simple-modal/simple-modal.contract";
import {RatesComponent} from "../../../../pages/booking-summary/rates/rates.component";
import {CurrencyPipe} from "../../../../service/currency.pipe";

@Component({
  selector: 'app-view-rates',
  templateUrl: './view-rates.component.html',
  styleUrls: ['./view-rates.component.scss'],
  providers: [CurrencyPipe]
})
export class ViewRatesComponent extends RatesComponent implements SimpleModalContent {

  @Input() title = 'View Rates';
  backdropIllustration = 'assets/images/rate_sheet_Illustration.svg';

  onCloseOptions: SimpleModalCloseOptions = {
    onModalClose: 'backAndClose',
    returnData: {activeTab: 'Account info'}
  };
  
  rateSheetCheck() {
    const bbratesheetid = this.ratesForm.get('bbratesheetid').value;
    super.rateSheetCheck(bbratesheetid);
  }

  saveRates() {
    super.saveRates();
  }

  refreshRates() {
    super.f_refreshRates()
  }

  changeRate(roomIndex: number, dayIndex: number){
    super.f_changeRate(roomIndex, dayIndex);
  }
}
