export class Stock{
    stockname?: string;
    price: number = 0.0;
    bbstockid: number;

    constructor() {
        this.price = 0.0;
    }

}
