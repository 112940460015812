import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SimpleModalContent} from '@shared/simple-modal/simple-modal.contract';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements SimpleModalContent {
  @Input() options;
  @Input() title;
  @Input() buttons;

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    if (this.options) {
      this.title = this.options.title;
      this.buttons = this.options.buttons;
    }
  }

  get isIconVisible(): boolean {
    return this.options?.iconVisible ?? true;
  }

  close() {
    this.activeModal.dismiss();
  }

  buttonClick(button) {
    this.close();
    button.callback();
  }
}
