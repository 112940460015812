import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DatelibraryService} from 'src/app/service/datelibrary.service';
import {ClientEdit} from 'src/app/service/models/ClientEdit';

@Component({
  selector: 'app-edit-client-transactions',
  templateUrl: './edit-client-transactions.component.html',
  styleUrls: ['./edit-client-transactions.component.scss']
})
export class EditClientTransactionsComponent {
  @Input() hasClient: boolean;
  @Input() clientObj: ClientEdit;
  @Input() pdfViewerActive;
  @Input() latestInvoice;
  @Input() latestCreditNote;
  @Input() invoiceTemplatePreference: string;
  @Input() constants;

  @Output() viewTransaction = new EventEmitter();
  @Output() addTransaction = new EventEmitter();
  @Output() openHTMLViewer = new EventEmitter();

  constructor(private datelibraryService: DatelibraryService) { }

  formatDate(date) {
    return this.datelibraryService.getLocalDate(date);
  }

  getAccountID(accountid) {
    let account = this.clientObj.accounts.filter((account) => {
      return account.accountid === accountid;
    })
    return account.length > 0 ? account[0].bbaccountid : accountid;
  }
}