<div class="d-flex flex-column gap-2 m-3" [formGroup]="clientTranForm">
  <div class="d-flex flex-column flex-sm-row gap-2">
    <cui-input type="select" class="flex-2" [dropdownOptions]="trantypes" dropdownOptionsLabel="description"
      dropdownOptionsValue="trantype" label="Type" [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'"
      formControlName="trantype" *ngIf="!trantype" class="flex-2">
    </cui-input>

    <div class="d-flex flex-column flex-sm-row  flex-3 gap-2">
      <cui-date-selector [viewMode]="2" label="Date" formControlName="trandate" dateText="Date" class="flex-1"
        [iconColor]="'color-mono-primary-text'" [readOnly]="true"> </cui-date-selector>

      <cui-input label="Amount" placeholder="Enter amount" classes="h-48" formControlName="amount" [sizing]="'md'"
        class="flex-1" (change)="updateVat()">
      </cui-input>
    </div>
  </div>


  <div class="d-flex flex-column flex-sm-row gap-2">
    <cui-input label="Reference" classes="h-48" placeholder="Enter reference" formControlName="reference"
      [sizing]="'md'" class="flex-2">
    </cui-input>

    <div class="d-flex flex-column flex-sm-row gap-2 flex-3">
      <cui-input type="select" class="flex-1" [dropdownOptions]="paymenttypes" dropdownOptionsLabel="paymenttypetext"
        dropdownOptionsValue="paymenttype" label="Payment Type"
        [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'" formControlName="paymenttype"
        *ngIf="clienttran.trantype === 'PMT'">
      </cui-input>

      <cui-input label="Vat" placeholder="Enter VAT" classes="h-48" formControlName="vat" [sizing]="'md'"
        class="flex-1" *ngIf="showVat()">
      </cui-input>
    </div>
  </div>
</div>