<div class="d-flex flex-column gap-3 m-3" [formGroup]="paymentAddForm">
  <div class="d-flex flex-column flex-sm-row gap-3">
    <cui-date-selector [viewMode]="2" [disabled]="mode === 'paymentEdit'" [readOnly]="true" label="Date" formControlName="paymentdate" dateText="Date" class="flex-1"
      [iconColor]="'color-mono-primary-text'"> </cui-date-selector>

    <cui-input type="select" class="flex-1" [dropdownOptions]="paymenttypes" dropdownOptionsLabel="paymenttypetext"
      dropdownOptionsValue="paymenttype" label="Type" [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'"
      formControlName="paymenttype" [disabled]="mode === 'paymentEdit'">
    </cui-input>
  </div>


  <div class="d-flex flex-column flex-sm-row gap-3">
    <cui-input label="Amount" placeholder="Enter amount" classes="h-48" formControlName="amount" [sizing]="'md'"
      class="flex-1" [disabled]="mode === 'paymentEdit'" [internalValue]="payment.amount | currency">
    </cui-input>

    <cui-input label="Reference" placeholder="Add reference number" [maxLength]="20" classes="h-48" formControlName="reference" [sizing]="'md'"
      class="flex-1">
    </cui-input>
  </div>
</div>
