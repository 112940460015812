import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CredentialService} from './credential.service';
import {TranslateService} from './translate.service';
import {Account} from './models/Account';
import {AccountSummary} from './models/AccountSummary';
import {environment} from '../../environments/environment';
import {Items} from './models/Items';
import {AccountType} from './models/enum/account-type.enum';
import {tap} from 'rxjs/operators';
import {NbLibraryService} from './nb-library.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private closeMessage = 'Do you want to close off all empty accounts?';
  private accounts: Account[] = [];
  private _accountsObserver: BehaviorSubject<Account[]>;
  private accountSummary: AccountSummary;
  private _accountSummaryObserver: BehaviorSubject<AccountSummary>;

  constructor(private http: HttpClient,
              private authService: CredentialService,
              private nbLib: NbLibraryService,
              private translateService: TranslateService) {
    this._accountsObserver = <BehaviorSubject<Account[]>>new BehaviorSubject(this.accounts);
    this._accountSummaryObserver = <BehaviorSubject<AccountSummary>>new BehaviorSubject(this.accountSummary);
  }

  closeAccounts() {
    const data = {
      messagename: 'AccountsZeroCloseRQ',
      credentials: {loginkey: this.authService.getLoginKey}
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body).toPromise();
  }

  get accountsSub() {
    return this._accountsObserver.asObservable();
  }

  setAccounts(accounts: any): void {
    this.accounts = accounts.data.accounts;
    this.accounts.forEach((account: Account) => {
      account.statusMessage = this.getStatusMessage(account);
    });
    this._accountsObserver.next(this.accounts);
  }

  searchAccounts(search: any) {
    const searchToSend = {...search};
    Object.keys(searchToSend).forEach((key) => {
      if (searchToSend[key] === undefined || searchToSend[key] === null || searchToSend[key] === '') {
        delete searchToSend[key];
      }
    });
    const data = {
      messagename: 'AccountsRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      ...searchToSend,
    }
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<Account[]>('/bridgeitapi', body).pipe(tap(data => {
      this.setAccounts(data);
    }));
  }

  get accountSummarySub() {
    return this._accountSummaryObserver.asObservable();
  }

  setAccountSummary(accountSummary: any) {
    this.accountSummary = accountSummary.data;
    this.accountSummary.account.statusMessage = this.getStatusMessage(this.accountSummary.account);
    this.accountSummary.account = this.calculateItems(this.accountSummary.items, this.accountSummary.account);
    this.accountSummary.account.outstanding = this.calculateTotals(this.accountSummary.account);
    this._accountSummaryObserver.next(this.accountSummary);
  }

  getAccountSummary(accountId: number) {
    const data = {
      accountid: accountId,
      messagename: 'AccountSummaryRQ',
      credentials: {loginkey: this.authService.getLoginKey}
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<AccountSummary>('/bridgeitapi', body).pipe(tap(data => {
      this.setAccountSummary(data);
    }));
  }

  get getClientId() {
    return this.accountSummary.client.clientid;
  }

  get getBBAccountId() {
    return this.accountSummary.account.bbaccountid;
  }

  calculateItems(items: Items[], account) {

    account.accomtotal = 0.00;
    account.extratotal = 0.00;
    account.paymenttotal = 0.00;

    for (let i = 0; i < items.length; i++) {
      if (items[i].itemtype === AccountType.LINE_ITEM_BOOKING) {
        account.accomtotal += items[i].price;
      } else if (items[i].itemtype === AccountType.LINE_ITEM_EXTRA || items[i].itemtype === AccountType.LINE_ITEM_CAPS_EXTRA) {
        if (items[i].debit !== 0) {
          account.extratotal += items[i].debit;
        } else if (items[i].credit !== 0) {
          account.extratotal -= items[i].credit;
        }
      } else if (items[i].itemtype === AccountType.LINE_ITEM_PAYMENT) {
        account.paymenttotal += Math.round(items[i].price * 100) / 100;
      }
    }
    account.accomtotal = account.accomtotal.toFixed(2);
    account.extratotal = account.extratotal.toFixed(2);
    account.paymenttotal = account.paymenttotal.toFixed(2);
    return account;
  }

  calculateTotals(account) {

    account.outstanding = Math.round((account.accomtotal * 100) + (account.extratotal * 100) - (account.paymenttotal * 100)) / 100;
    if (Math.abs(account.outstanding) === 0) {
      account.outstanding = Math.abs(account.outstanding);
    }
    return account.outstanding.toFixed(2);
  }

  getStatusMessage(account: Account) {
    if (account.status === 'O') {
      return this.translateService.translate('accounts', 'uninvoiced');
    }
    if (account.status === 'C') {
      return this.translateService.translate('accounts', 'close');
    }
    return '';
  }

  /* ========================
  *      OPEN DOCUMENTS
  *  ========================*/

  openStatement(invoiceTo: string) {
    let url = '';
    const credentials = this.authService.getLoginKey;
    url = environment.apiUrl + '/documents/' + credentials + '/STATEMENT/' + invoiceTo;
    window.open(url, '_blank');
  }

  openTotalStatement(invoiceTo: string){
    let url = '';
    const credentials = this.authService.getLoginKey;
    url = environment.apiUrl + '/documents/' + credentials + '/TOTAL_STATEMENT/' + invoiceTo;
    window.open(url, '_blank');
  }

  generateProForma(accountId: number) {
    const data = {
      accountid: accountId,
      messagename: 'ProformaCreateRQ',
      credentials: {loginkey: this.authService.getLoginKey}
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body).pipe(tap((data: any) => {
      if (data.success) {
        this.accountSummary.account.proformano = data.data.proformano;
        this._accountSummaryObserver.next(this.accountSummary);
      }
    }));
  }

  generateInvoice(accountId: number) {
    const data = {
      accountid: accountId,
      messagename: 'InvoiceCreateRQ',
      credentials: {loginkey: this.authService.getLoginKey}
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body).pipe(tap((data: any) => {
      if (data.success) {
        this.accountSummary.account.invoiceno = data.data.invoiceno;
        this._accountSummaryObserver.next(this.accountSummary);
      }
    }));
  }

  openDocumentLink(docType: string): void {
    const url = environment.apiUrl + '/documents/' + this.authService.getLoginKey + docType;
    window.open(url, '_blank');
  }

  addPayment(paymentObject) {
    const data = {
      messagename: 'PaymentAddRQ',
      credentials: {loginkey: this.authService.getLoginKey}
    };
    const CombinedData = Object.assign(data, paymentObject);
    const body = new HttpParams();
    body.set('data', JSON.stringify(CombinedData));
    return this.http.post('/bridgeitapi', body).pipe(tap(data => {
      if (data) {
        this.getAccountSummary(this.accountSummary.account.accountid);
      }
    }));
  }

  openAccountAgain(accountId: number) {
    const data = {
      accountid: accountId,
      messagename: 'AccountReOpenRQ',
      credentials: {loginkey: this.authService.getLoginKey}
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post('/bridgeitapi', body).pipe(tap((data: any) => {
      if (data.data.accountreopened === 1) {
        this.accountSummary.account.status = 'O';
        this.accountSummary.account.statusMessage = this.getStatusMessage(this.accountSummary.account);
        this.accountSummary.account.invoiceno = undefined;
        this._accountSummaryObserver.next(this.accountSummary);
      }
    }));
  }

  closeAccountAgain(accountId: number) {
    const data = {
      accountid: accountId,
      messagename: 'AccountCloseRQ',
      credentials: {loginkey: this.authService.getLoginKey}
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post('/bridgeitapi', body).pipe(tap((data: any) => {
      this.accountSummary.account.status = 'C';
      this._accountSummaryObserver.next(this.accountSummary);
    }));
  }

  saveAccount(account) {
    const data = {
      messagename: 'AccountSaveRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      account: account,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  private getAccountObj(account: Account) {
    return {
      reference: account.reference,
      vatcode: account.vatcode,
      accountid: account.accountid,
      bbid: account.bbid,
      invoiceto: account.invoiceto,
      bbaccountid: account.bbaccountid,
      invoiceno: account.invoiceno,
      proformano: account.proformano,
      status: account.status,
      accomtotal: account.accomtotal,
      extratotal: account.extratotal,
      paymenttotal: account.paymenttotal,
      statusdisplay: account.statusMessage,
      outstanding: account.outstanding
    };
  }

  getPayment(paymentid: number) {
    const data = {
      paymentid: paymentid,
      messagename: 'PaymentRQ',
      credentials: {loginkey: this.authService.getLoginKey}
    };
    const body = new HttpParams();
    body.set('data', JSON.stringify(data));
    this.http.post('/bridgeitapi', body).subscribe((data: any) => {

    });
  }

  savePayment(paymentData: any) {
    const data = {
      messagename: 'PaymentAddRQ',
      credentials: {loginkey: this.authService.getLoginKey}
    };
    const combinedData = Object.assign(data, paymentData);
    const body = new HttpParams();
    body.set('data', JSON.stringify(combinedData));
    this.http.post('/bridgeitapi', body).subscribe(data => {

    });
  }

  createCreditLink(clientid, accountid, enablethreedsecure) {
    const data = {
      messagename: 'CreditLinkCreateRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      clientid: clientid,
      accountid: accountid,
      enablethreedsecure: enablethreedsecure,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  addAccount(clientid) {
    const data = {
      messagename: 'AccountAddRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      clientid: clientid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  updateClientAccount(type, id, accountid) {
    const data = {
      messagename: 'ClientAccountUpdateRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      type: type,
      id: id,
      accountid: accountid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  clientAccounts(status, invoiceto) {
    const data = {
      messagename: 'ClientAccountsRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      status: status,
      invoiceto: invoiceto,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  clientAccount(type, id, accountid) {
    const data = {
      messagename: 'ClientAccountRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      type: type,
      id: id,
      accountid: accountid,
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    return this.http.post<any>('/bridgeitapi', body);
  }

  getClientFullName(contact): string {
    let fullName = '';
    contact = contact || {};
    contact.title = contact.title || '';
    contact.firstname = contact.firstname || '';
    contact.surname = contact.surname || '';
    contact.company = contact.company || '';

    fullName += (contact.title.length > 0) ? contact.title + ' ' : '';
    fullName += (contact.firstname.length > 0) ? contact.firstname + ' ' : '';
    fullName += (contact.surname.length > 0) ? contact.surname + ' ' : '';
    fullName += (contact.company.length > 0) ? ((fullName.length > 0) ? '@ ' + contact.company : fullName += '#' + contact.company) : '';
    return fullName.trim();
  }
}
