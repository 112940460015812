<div [formGroup]="bookingInfoForm" class="m-2">
    <div class="d-flex mb-2 flex-column extra-small-row gap-2">
        <div class="flex-3">
            <cui-info-bar>
                <div class="d-flex justify-content-evenly justify-content-sm-center gap-2 px-3">

                    <div class="d-none d-sm-block p-1 fs-20 star-icon">
                        <svg class="ic medium-icon align-self-center color-secondary-text ic-24">
                            <use href="assets/images/sprite.svg#star" />
                        </svg>
                    </div>

                    <div class="d-flex gap-1 flex-column flex-sm-row flex-1 flex-sm-0">
                        <div class="fw-semibold fs-14">
                            NBID:
                        </div>

                        <div class="fw-normal fs-14">
                            {{bookingSummary?.booking?.bookingid}}
                        </div>
                    </div>

                    <div class="lh-45 lh-sm-normal flex-1 flex-sm-0 text-center"> • </div>

                    <div class="d-flex gap-1 flex-column flex-sm-row flex-sm-0">
                        <div class="fw-semibold fs-14">
                            Booking ID:
                        </div>

                        <div class="fw-normal fs-14">
                            {{bookingSummary?.booking?.bbbookingid}}
                        </div>
                    </div>

                </div>
            </cui-info-bar>
        </div>
        <div class="flex-1">
            <cui-input label="Reference" classes="h-48 w-100" formControlName="reference" [sizing]="'md'"></cui-input>
        </div>
    </div>

    <div class="d-flex mb-2 gap-2">
        <div class="flex-3">
            <cui-input type="select" class="w-100x" [dropdownOptions]="statusCodes" dropdownOptionsLabel="description"
                dropdownOptionsValue="status" label="Status" [trailingIconColor]="'color-secondary-text'"
                [trailingIcon]="'assets/images/sprite.svg#keyboard-arrow-down'" formControlName="status"
                (change)="statusChanged.emit(bookingInfoForm.get('status').value)">
            </cui-input>
        </div>
        <div class="flex-1 d-none d-sm-block">
            <!-- PLACEHOLDER DIV: DO NOT DELETE -->
        </div>
    </div>

    <div class="d-flex mb-2 gap-2"
        *ngIf="bookingInfoForm.get('status').value == 'P' || bookingInfoForm.get('status').value == 'R'">
        <div class="flex-3">
            <cui-date-selector [viewMode]="2" dateText="Until" formControlName="statusdate" [readOnly]="true"
                [iconColor]="'color-mono-primary-text'">
            </cui-date-selector>
        </div>
        <div class="flex-1 d-none d-sm-block">
            <timepicker formControlName="statustime" [showSpinners]="false" [showMeridian]="false" class="h-48"
                ngbTooltip="{{ 'bookingSummary'| translate: 'hourMin'}}" tooltipClass="tooltip__container">
            </timepicker>
        </div>
    </div>

    <div class="d-flex mb-2">
        <app-booking-date-range [range]="dateRange" [labelMode]="2" (onChange)="dateRangeChanged.emit($event)"
            class="w-100">
        </app-booking-date-range>
    </div>

    <div class="d-flex align-items-center mb-2">
        <span class="fs-16 fw-semibold">Booking Info</span>
    </div>

    <div class="d-flex flex-column flex-sm-row mb-2 gap-2">

        <div class="flex-sm-3">
            <div class="position-relative">
                <cui-input mode="secondary" label="Booking Made By" class="w-100 d-none d-sm-block" [sizing]="'md'" formControlName="madebyclientfullname"
                    [readonly]="true">
                </cui-input>
                <cui-input mode="secondary" label="Booking Made By" class="w-100 d-block d-sm-none" [sizing]="'md'" formControlName="madebyclientfullname"
                    [readonly]="true" trailingIcon="assets/images/sprite.svg#email" (trailingIconClick)="sendEmail.emit(bookingSummary.madebyclient?.email)">
                </cui-input>
            </div>
        </div>

        <div class="flex-sm-1">
            <div class="d-flex flex-row my-1 mt-sm-2">
                <button class="btn__icon btn__icon--round-desktop btn__regular-icon--active-green w-100 h-48 h-sm-36"
                    (click)="editClient.emit(bookingSummary.madebyclient.clientid)"
                    ngbTooltip="{{'bookingSummary' | translate:'clientEdit'}}" tooltipClass="tooltip__container">
                    <div class="d-flex align-items-center justify-content-center gap-1">
                        <svg class="ic ic_person">
                            <use href="assets/images/sprite.svg#person" />
                        </svg>
                        <span class="color-success-primary-text fw-semibold d-sm-none">Edit Client</span>
                    </div>
                </button>

                <button
                    class="btn__icon btn__icon--round-desktop btn__regular-icon--active-blue ms-1 w-100 h-48 h-sm-36"
                    (click)="changeMadeByClient.emit()" ngbTooltip="Change Client" tooltipClass="tooltip__container">
                    <div class="d-flex align-items-center justify-content-center gap-1">
                        <svg class="ic ic_add_person">
                            <use href="assets/images/sprite.svg#person-add" />
                        </svg>
                        <span class="color-secondary-text fw-semibold d-sm-none">Change Client</span>
                    </div>
                </button>

                <button
                    class="btn__icon btn__icon--round-desktop btn__regular-icon--active-blue ms-1 w-100 h-48 h-sm-36 d-none d-sm-block"
                    [disabled]="!bookingSummary.madebyclient?.email" ngbTooltip="Email Client"
                    tooltipClass="tooltip__container">
                    <a (click)="sendEmail.emit(bookingSummary.madebyclient?.email)"
                        href="mailto:{{bookingSummary.madebyclient?.email}}">
                        <div class="d-flex align-items-center justify-content-center">
                            <svg class="ic ic-mail">
                                <use href="assets/images/sprite.svg#email" />
                            </svg>
                        </div>
                    </a>
                </button>
            </div>
        </div>

    </div>

    <div class="d-flex flex-column flex-sm-row gap-2 mb-2">
        <div class="flex-sm-3">
            <div class="position-relative">
                <cui-input type="text" label="Source" class="w-100 d-block d-sm-none" formControlName="source"
                    [sizing]="'md'" [readonly]="true" trailingIcon="assets/images/icons/sprite.svg#ic_search" (trailingIconClick)="searchSources.emit()" (click)="searchSources.emit()">
                </cui-input>
                <cui-input type="text" label="Source" class="w-100 d-none d-sm-block" formControlName="source"
                    [sizing]="'md'" [readonly]="true">
                </cui-input>
            </div>

        </div>

        <div class="flex-1">
            <div class="d-flex gap-1">
                <div *ngIf="bookingSummary.showsourcereplace" class="d-none d-sm-block">
                    <button class="btn__icon btn__secondary-icon btn__icon--round-desktop border-radius-50 mt-2"
                        (click)="searchSources.emit()" ngbTooltip="Search Marketing Sources"
                        tooltipClass="tooltip__container">
                        <svg class="ic ic_search ic_center">
                            <use href="assets/images/icons/sprite.svg#ic_search" />
                        </svg>
                    </button>
                </div>

                <cui-input label="Commission" formControlName="agentcomm" [sizing]="'md'"
                    class="d-none d-sm-block commission-input"
                    [classes]="adjustCommissionControlWidth(bookingSummary.showsourcereplace)" [removeBorder]="true"
                    (input)="onCommissionInput($event)"></cui-input>
            </div>
        </div>
    </div>

    <div class="d-flex align-items-center justify-content-end me-2 d-block d-sm-none">
        <span label="Commission">Commission: {{ bookingInfoForm.get('agentcomm').value }}</span>
    </div>
</div>
