import {HttpClient, HttpParams} from '@angular/common/http';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {STATEMENT_TYPE} from '@core/models/enum';
import {UserService} from '@core/services/user.service';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {SimpleModalCloseOptions, } from '@shared/simple-modal/simple-modal.contract';
import {EmailConfirmationComponent} from 'src/app/components/booking-info/widgets/email-confirmation/email-confirmation.component';
import {ProFormaEmailModalComponent} from 'src/app/pages/accounts/accounting-documents/accountingdocuments.component';
import {AccountService} from 'src/app/service/account.service';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {CredentialService} from 'src/app/service/credential.service';
import {EmailService} from 'src/app/service/email.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {isMobileDevice} from 'src/utils';

@Component({
  selector: 'app-guest-statement-preview',
  templateUrl: './guest-statement-preview.component.html',
  styleUrls: ['./guest-statement-preview.component.scss']
})
export class GuestStatementPreviewComponent implements OnInit {

  @ViewChild('proformaIframe') iframe: ElementRef<HTMLIFrameElement>;
  @Input() options;
  @Input() title: string = "Guest Statement Preview";
  @Input() buttons = [
    {
      text: 'printInvoice',
      type: isMobileDevice() ? 'icon' : 'button',
      leadingIcon: 'print',
      class: 'btn__primary_navy_light',
      wrappingClass: 'me-auto',
      callback: () => this.printStatement(),
    },
    {
      text: 'download',
      leadingIcon: 'download',
      class: 'btn__outlined',
      wrappingClass: 'ms-2',
      callback: () => this.downloadStatement()
    },
    {
      text: 'email',
      leadingIcon: 'email',
      wrappingClass: 'ms-2',
      callback: () => this.sendEmail()
    }
  ];

  footerFlexboxAlignment = 'between';
  onCloseOptions: SimpleModalCloseOptions = {
    preventAutoClose: true,
    onModalClose: 'backAndClose',
    showBackButton: true
  };

  backdropIllustration = 'assets/images/invoice_preview_Illustration.svg';
  html: any;

  modalInstance: NgbModalRef;
  ngbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    backdropClass: "modal-backdrop-overlay",
    windowClass: "modal-window-centered",
    modalDialogClass: 'modal-padding',
    size: 'sm'
  } as NgbModalOptions;

  constructor(
    private authService: CredentialService,
    private accountService: AccountService,
    private confirmationService: ConfirmationService,
    private pageRouteService: PageRouteService,
    private http: HttpClient,
    private emailService: EmailService,
    private credentialService: CredentialService,
    protected modalService: NgbModal) { }


  ngOnInit(): void {
    if (!this.options.clientid || !this.options.type) {
      this.pageRouteService.close();
      return
    }
    this.onCloseOptions.returnData = this.options;
    this.getHtml();
  }

  async getHtml() {
    let data = {
      template: 'guest_statement',
      clientid: this.options.clientid,
      messagename: 'DocumentTemplateHTMLRQ',
      credentials: {loginkey: this.authService.getLoginKey},
      totalresults: this.totalResults()
    };
    let body = new HttpParams();
    body = body.set('data', JSON.stringify(data));
    this.http.post<any>('/bridgeitapi', body).subscribe(response => {
      if (response.success) {
        this.html = URL.createObjectURL(
          new Blob([response.data.doctemplatehtml], {type: "text/html"})
        );
      } else {
        this.pageRouteService.back(this.options, true);
      }
    });
  }

  downloadStatement() {
    this.accountService.openDocumentLink(`/DOCUMENT_FACTORY/guest_statement/${this.options.clientid}/${this.options.type === STATEMENT_TYPE.OUT ? '/false' : '/true'}`);
  }

  printStatement() {
    this.iframe.nativeElement.contentWindow.print();
  }

  totalResults(): boolean {
    return this.options.type === STATEMENT_TYPE.OUT ? false : true;
  }

  sendEmail() {
    if (this.altComponentDisplayActive()) {
      this.confirmationService.custom(EmailConfirmationComponent, {
        email: this.options.email,
        title: 'Guest Statement Confirmation',
        template: 'guest-statement',
        modalOptions: {
          fullscreen: false,
          modalDialogClass: 'modal-padding model-rounded'
        },
        buttons: [{
          text: 'send',
          class: 'btn__primary',
          callback: (result: any) => {
            this.emailService.setCopyValue(result.bcc);
            this.emailService.sendGuestStatementEmail(result.email, this.options.clientid, result.bcc, this.totalResults());
          }
        }]
      });
    } else {
      this.modalInstance = this.modalService.open(ProFormaEmailModalComponent, this.ngbModalOptions);
      this.options.template = "gueststatement";
      this.options.totalResults = this.totalResults();
      this.modalInstance.componentInstance.options = this.options;
    }
  }

  altComponentDisplayActive() {
    const user = this.credentialService.getCurrentUser.value;
    return user.altComponentDisplayActive;
  }

  back(returnData?) {
    this.pageRouteService.back(returnData);
  }
}
