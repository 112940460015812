<app-tap-n-pay-modal-container>
        <div class="d-flex justify-content-center my-1">
            <img  class="h-100" src="../../../assets/images/icons/info-error.svg" />
        </div>
        <div class="w-360 text-center fs-16">
            <div class="p-1 fw-semi-bold"> Payment {{mode | lowercase}}. </div>
          <ng-container [ngSwitch]="mode">
            <div class="wrap-text w-360" *ngSwitchCase="TapNpayStatuses.DECLINED" >
              Please ensure that the payment details are correct and try again later.
            </div>
            <div class="wrap-text w-360" *ngSwitchCase="TapNpayStatuses.CANCELLED">
              The payment has been cancelled, please try again or close Tap & Pay.
            </div>
            <div class="wrap-text w-360" *ngSwitchCase="TapNpayStatuses.FAILED">
              There was a failure after tapping your card, please try again or use a different payment method.
            </div>
          </ng-container>
        </div>
</app-tap-n-pay-modal-container>

<div class="d-flex modal-footer border-top justify-content-between">
    <cui-button class="d-flex flex-1" (onClick)="retry()" classes="tap-n-pay-btn-cancel tap-n-pay-btn-action-override" mode="light" label="Retry" name="retry"></cui-button>
</div>

