<div class="booking-details-container d-flex flex-column vh-100">
  <div class="d-flex align-items-center">
    <div class="fs-18 fw-bold p-12 ps-3">Booking Details</div>
    <span class="flex-1"></span>
    <cui-button type="close" class="mx-2 mt-2" classes="btn-close-alt" aria-label="Close" (click)="close()"></cui-button>
  </div>

    <app-simple-tabs class="side-panel-tabs flex-grow-1 overflow-auto">
      <ng-template appTab title="Booking info" [active]="activeTab == 'Booking info'">
        <app-booking-info-summary
          [bookingInfoForm]="bookingSummaryForm"
          [bookingSummary]="bookingSummaryObj"
          [statusCodes]="statuscodes"
          [statuscodeMap]="statuscodeMap"
          [dateRange]="dateRange"
          (dateRangeChanged)="dateRangeChanged($event)"
          (statusChanged)="statusChanged($event)"
          (editClient)="editClient($event)"
          (changeMadeByClient)="changeMadeByClient()"
          (sendEmail)="sendEmail($event)"
          (searchSources)="searchSources()"
          (openUntilModal)="openUntilModal()">
        </app-booking-info-summary>
        <app-booking-room-info
        [options]="options"
        [guestAccountExists]="guestAccountExists"
        [bookingInfoForm]="bookingSummaryForm"
        [roomFilter]="roomFilter"
        (addRoom)="addRoom()"
        (changeRoom)="changeRoom()"
        (splitRoom)="splitRoom()"
        (cancelRoom)="cancelRoom()"
        (editClient)="editClient($event)"
        (changeGuestClient)="changeGuestClient()"
        (sendEmail)="sendEmail($event)"
        (viewGuestAccount)="viewGuestAccount()"
        (editClient2)="editClient2($event)"
        (changeGuest2Client)="changeGuest2Client()"
        (validateCheckIn)="validateCheckIn()"
        (validateCheckOut)="validateCheckOut()"
        (guestCommunications)="guestCommunications()"
        (changeRoomView)="changeRoomView($event)"
        (viewExtras)="viewExtras()"
        (toggleRates)="toggleRates()"
        (clearGuest2Client)="clearGuest2Client()"
        ></app-booking-room-info>
      </ng-template>
      <ng-template appTab title="Account info" [active]="activeTab == 'Account info'">
        <app-account-info
                (viewAccountClicked)="viewInvoiceAccount()"
                (viewRatesClicked)="viewRates()"
                (checkoutClicked)="payOnCheckOut()"
                (invalidccClicked)="reportInvalidCardConfirmation()"
                (noShowClicked)="reportNoShowConfirmation()"
                (calculateDepositClicked)="calculateDeposit()"
                (updateDeposit)="updateDeposit()"
                (viewStatusAndHistoryClicked)="redirectToBookingPaymentStatus()"
                [bookingSummaryObject]="bookingSummaryObj"
                [bookingSummaryForm]="bookingSummaryForm"
                [reportInvalidCCBookingStatus]="invalidCreditCardStatus$"
                [invalidCCReported]="invalidCCReported"
                [reportNoShowStatus]="noShowStatus$"
                [noShowReported]="noShowReported"
                [typeReported]="typeReported"
        >
        </app-account-info>
      </ng-template>
      <ng-template appTab title="Notes" [active]="activeTab == 'Notes'">
        <app-booking-notes
          [bookingId]="bookingSummaryObj.booking?.bookingid"
          [bookingSummaryForm]="bookingSummaryForm">
        </app-booking-notes>
      </ng-template>
    </app-simple-tabs>


  <div class="button-container d-flex p-3 gap-1 border-top m-2 mt-auto">
    <cui-button type="tonal" mode="cancel" class="flex-1 h-40" classes="min-w-120 no-wrap" (click)="cancelBooking()">Cancel Booking</cui-button>
    <span class="flex-none flex-sm-1"></span>
    <cui-button type="tonal" mode="success" class="flex-1 h-40 px-3" classes="min-w-120" (click)="preSaveBooking()"
      [disabled]="hasInvalidInput()">Save</cui-button>
  </div>
</div>
