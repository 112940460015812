<div class="container">
  <div class="modal-header">
      <span></span>
      <div class="">
          <label class="typography__h3">{{'accountSummary' | translate: 'guestStatementTitle'}}</label>
      </div>
      <div class="close-modal" (click)="back(options)">
          <span class="helper"></span>
          <img src="assets/images/close.svg" class="display__end"/>
      </div>
  </div>
  <div class="invoice__grid">
      <section>
          <iframe #proformaIframe id="iframe" [src]="html| safe: 'resourceUrl'">
              <p>{{'general' | translate: 'error'}}</p>
          </iframe>
      </section>
      <aside>
          <button type="button" class="btn btn__primary btn__regular btn__fullWidth btn__email" (click)="sendEmail()">{{'accountSummary' | translate: 'emailProForma'}}</button>
          <button type="button" class="btn btn__primary btn__regular btn__fullWidth" (click)="printStatement()">{{'accountSummary' | translate: 'printProForma'}}</button>
          <button type="button" class="btn btn__primary btn__download btn__fullWidth" (click)="downloadStatement()">
              <span>{{'general' | translate: 'download'}}</span>
              <svg class="ic ic_download">
                  <use href="assets/images/icons/sprite.svg#ic_download" />
              </svg>
          </button>
      </aside>
  </div>
  <div class="modal-footer"></div>
</div>
