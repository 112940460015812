import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {TapNpayService} from "../../tap-n-pay.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TapNpayDeviceBT, TapNpayDeviceNB, TapNpayDeviceStatuses, TapNpaySource, TapNpaySources} from '../../tap-n-pay.class';

@Component({
  selector: 'app-tap-n-pay-modal-devices',
  templateUrl: './tap-n-pay-modal-devices.component.html',
  styleUrls: ['./tap-n-pay-modal-devices.component.scss']
})
export class TapNpayModalDevicesComponent {

  protected readonly TapNpaySources = TapNpaySources;

  isConfirmDisabled = false;

  @Input() devicesBT: TapNpayDeviceBT[] = [];
  @Input() devicesNB: TapNpayDeviceNB[] = [];

  devicesOnPageBT: TapNpayDeviceBT[] = [];
  devicesOnPageNB: TapNpayDeviceNB[] = [];

  selectedDeviceIndex: number | undefined = undefined;

  source: TapNpaySource | undefined;

  isRefund: boolean | undefined = false;

  constructor(
    private tapNpayService: TapNpayService,
    private changeDetectorRef: ChangeDetectorRef,
    public activeModal: NgbActiveModal
  ) {
    this.source = this.tapNpayService.getConfiguration().source;
    this.isRefund = this.tapNpayService.paymentDetails.value?.mode === 'refund';
  }

  setDevicesOnPageBT(devices: unknown[]) {
    this.devicesOnPageBT = devices as TapNpayDeviceBT[];
    this.resetSelectedDeviceIndex();
    this.changeDetectorRef.detectChanges();
  }

  setDevicesOnPageNB(devices: unknown[]) {
    this.devicesOnPageNB = devices as TapNpayDeviceNB[];
    this.resetSelectedDeviceIndex();
    this.changeDetectorRef.detectChanges();
  }

  refreshDeviceList() {
    this.resetSelectedDeviceIndex();

    this.activeModal.close();

    this.tapNpayService.getDevices(this.activeModal);
  }

  selectDeviceBT(device: TapNpayDeviceBT, index: number) {
    if(device.state === 'DEVICE_STATUS_ENABLED') {
      this.selectedDeviceIndex = index;
      this.tapNpayService.updateDeviceDetailsBT(device);
      this.isConfirmDisabled = false;
    }
  }

  selectDeviceNB(device: TapNpayDeviceNB, index: number) {
    if(device.state === 'DEVICE_STATUS_ENABLED') {
      this.selectedDeviceIndex = index;
      this.tapNpayService.updateDeviceDetailsNB(device);
      this.isConfirmDisabled = false;
    }
  }

  resetSelectedDeviceIndex() {
    this.selectedDeviceIndex = undefined;
    this.isConfirmDisabled = true;
  }

  close() {
    this.activeModal.close();
  }

  back() {
    this.activeModal.close();
    this.tapNpayService.openAmountModal();
  }

  confirm() {
    this.tapNpayService.processPayment(this.activeModal);
  }

  protected readonly TapNpayDeviceStatuses = TapNpayDeviceStatuses;
}
