import {Component, OnInit} from '@angular/core';
import {ToastService} from '@nightsbridge/common-ui/toasts';
import {AccountMoveItemComponent} from 'src/app/components/account-info/widgets/account-summary/account-move-item/account-move-item.component';
import {ConfirmationService} from 'src/app/service/confirmation.service';
import {CredentialService} from 'src/app/service/credential.service';
import {DisplayManagerService} from 'src/app/service/display-manager.service';
import {LoadingService} from 'src/app/service/loading.service';
import {PageRouteService} from 'src/app/service/page-route.service';
import {PaymentService} from 'src/app/service/payment.service';
import {TranslateService} from 'src/app/service/translate.service';

@Component({
  selector: 'app-move-payment',
  templateUrl: './move-payment.component.html',
  styleUrls: ['./move-payment.component.scss']
})
export class MovePaymentComponent extends AccountMoveItemComponent implements OnInit {

  constructor(
    pageRouteService: PageRouteService,
    paymentService: PaymentService,
    translateService: TranslateService,
    authService: CredentialService,
    toastService: ToastService,
    loadingService: LoadingService,
    confirmationService: ConfirmationService) {
    super(
      pageRouteService,
      paymentService,
      translateService,
      authService,
      toastService,
      loadingService,
      confirmationService,
    );
  }

  close(returnData?) {
    this.pageRouteService.back(returnData);
  }

}
