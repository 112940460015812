<div class="flex-grow-1 d-sm-none mb-sm-0 mb-2 me-sm-2 h-48">
    <cui-date-selector
            [(ngModel)]="startDate"
            [viewMode]="2"
            [dateText]="'dateRange' | translate:( labelMode === 1 ? 'startDate' : 'arrival')"
            [readOnly]="true"
            [iconColor]="'color-mono-primary-text'"
    >
    </cui-date-selector>
</div>

<div class="d-flex gap-2  h-48">
    <div class="d-flex flex-1 flex-sm-3 gap-2">

        <div class="flex-sm-1 d-none d-sm-block">
          <cui-date-selector
            [(ngModel)]="startDate"
            [viewMode]="2"
            [dateText]="'dateRange' | translate:( labelMode === 1 ? 'startDate' : 'arrival')"
            [readOnly]="true"
            [iconColor]="'color-mono-primary-text'"
          >
          </cui-date-selector>
        </div>

        <div class="inputField__label align-self-center d-none d-sm-block" *ngIf="!showLabel">
            {{'dateRange' | translate: 'toDate'}}
        </div>

        <div class="flex-sm-1">
          <cui-date-selector
            [(ngModel)]="endDate"
            [viewMode]="2"
            [dateText]="'dateRange' | translate:( labelMode === 1 ? 'endDate' : 'departure')"
            [readOnly]="true"
            [iconColor]="'color-mono-primary-text'"
          >
          </cui-date-selector>
        </div>

    </div>


    <div class="flex-1">
        <cui-input
          type="number"
          min="1"
          max="365"
          [label]="'dateRange' | translate:(labelMode === 1 ? 'days' : 'nights')"
          [(ngModel)]="duration"
          sizing="md"
          (input)="onInputChange($event)">
        </cui-input>
    </div>
</div>
